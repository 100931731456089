import React, { useEffect, useState } from "react";
import { BASE_URL, deleteData, fetchData, fetchDataById } from "../../../../BaseUrl";
import MaterialTypography from "../../../../components/MaterialTypography";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import MaterialDataGrid from "../../../../components/MaterialDataGrid";
import DashboardLayout from "../../../../layoutcontainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
import bgImage from "../../../../assest/bg-image.png";

function CrimeReportTable() {
  const [crimeData, setCrimeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false); 
  const [deleteId, setDeleteId] = useState(null);
  const [user, setUser] = useState()

  useEffect(() => {
    getUserId();
  }, []);



  const onView = async (id) => {
    try {
      const item = await fetchDataById(`api/user/${id}`);
    } catch (error) {
      console.error(`Error fetching item with ID ${id}:`, error);
    }
  };

  const onEdit = async (id) => {
    navigate("/crime-reporting-form", { state: { id: id } });
  };

  const onDelete = (id) => {
    setDeleteId(id); 
    setOpenDialog(true); 
  };

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "incident_id", headerName: "Incident id", width: 200 },
    { field: "userId", headerName: "User Id", width: 200 },
    { field: "userName", headerName: "User Name", width: 200, },
    { field: "crimeType", headerName: "Crime Type", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    { field: "subHead", headerName: "Sub Head", width: 200 },
    { field: "crimestatus", headerName: "crime Status", width: 200 },
    { field: "latitude", headerName: "Latitude", width: 200, },
    { field: "longitude", headerName: "Longitude", width: 200 },
    { field: "date", headerName: "Date", width: 200 },
    { field: "time", headerName: "Time", width: 200, },
    { field: "city", headerName: "City", width: 200 },
    { field: "district", headerName: "District", width: 200 },
    { field: "range", headerName: "Range", width: 200 },
    { field: "station_location", headerName: "Station", width: 200 },  
    { field: "investigated_by", headerName: "Investigated by", width: 200 },
    { field: "station_jurisdiction", headerName: "Station jurisdiction", width: 200, },
    { field: "red_flag_zone", headerName: "Red flag zone", width: 200 },
    { field: "reportedCrimeDetails", headerName: "Reported CrimeDetails", width: 200 },
    { field: "actions", headerName: "Actions", width: 200 },
  ];

  const handleDialogConfirm = async () => {
    if (deleteId) {
      try {
        const result = await deleteData(`api/crime/${deleteId}`);
        getCrimeData();
        setDeleteId(null);
      } catch (error) {
        console.error(`Error deleting item with ID ${deleteId}:`, error);
      }
    }
    setOpenDialog(false); 
  };

 
  const handleDialogCancel = () => {
    setDeleteId(null); 
    setOpenDialog(false); 
  };

  useEffect(() => {
    if(user !== ""){
      getCrimeData();
    }
    
  }, [user]);

  const getUserId = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found.");
        return;
      }

      const response = await fetch(`${BASE_URL}api/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("response", response);
      const result = await response.json();
      console.log("resultuserId", result.userId);
     
        if (result && result.userId) {
          setUser(result.userId);
        } else {
          // console.log("No userId found in the response.");
        }
    } catch (error) {
      console.error("Error fetching userId:", error);
    }
  };
  const getCrimeData = async () => {
    try {
      const result = await fetchData(`api/crimeIncidents/${user}`);
      setCrimeData(result.data); // Set the crime data to state
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }finally {
        setLoading(false);
      }
  };
  return (
    <DashboardLayout>
      <div sx={{
            position: "relative",
          }}>
            <img
            src={bgImage}
            alt="background"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.2,
            }}
          />
      <MaterialTypography>CrimeReportTable</MaterialTypography>
      <MaterialDataGrid
        rows={crimeData}
        columns={columns}
        loading={loading}
        onRowClick={(params) => console.log(params.row)}
        customHeight={400}
        isSelectable={true}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
      />
      <ConfirmationDialog
        open={openDialog} 
        showCancel={true} 
        showConfirm={true} 
        title="Confirm Deletion"
        message="Are you sure you want to delete this crime type?" 
        onConfirm={handleDialogConfirm} 
        onCancel={handleDialogCancel} 
      />
      </div>
    </DashboardLayout>
  );
}

export default CrimeReportTable;
