import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialInput from "../../../../components/MaterialInput";
import MaterialButton from "../../../../components/MaterialButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { postData, putData } from "../../../../BaseUrl";
import LocationData from "../crimeform/LocationData";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StationDetailFrom = ({ open, setOpen, getStationData, oneStationData }) => {

  const navigate = useNavigate();
  
  const [stationDetails, setStationDetails] = useState({
    stationId: "",
    stationLocation: "",
    latitude: "",
    longitude: "",
    stationRange: "",
    stationDistrict: "",
    stationCity: "",
    stationIdStatus: "unchecked",
    stationLocationStatus: "unchecked",
    latitudeStatus: "unchecked",
    longitudeStatus: "unchecked",
    stationRangeStatus: "unchecked",
    stationDistrictStatus: "unchecked",
    stationCityStatus: "unchecked",
  });

  useEffect(() => {
    if (oneStationData) {
      setStationDetails({
        stationId: oneStationData.station_id || "",
        stationLocation: oneStationData.station_location || "",
        latitude: oneStationData.latitude || "",
        longitude: oneStationData.longitude || "",
        stationRange: oneStationData.range || "",
        stationDistrict: oneStationData.district || "",
        stationCity: oneStationData.city || "",
        stationIdStatus: "checked", 
        stationLocationStatus: "checked", 
        latitudeStatus: "checked", 
        longitudeStatus: "checked",
        stationRangeStatus: "checked",
        stationDistrictStatus: "checked",
        stationCityStatus: "checked",
      });
    }
  }, [oneStationData]);

  const handleStationDataChange = (event) => {
    const { name, value } = event.target;
    let stationState = { ...stationDetails };

    switch (name) {
      case "stationId":
        const stationIdRegex = /^[a-zA-Z0-9]+$/;
        stationState[name + "Status"] =
          value === ""
            ? "unchecked"
            : stationIdRegex.test(value.trim())
            ? "success"
            : "error";
        break;
      case "stationLocation":
        stationState[name + "Status"] = value === "" ? "unchecked" : "success";
        break;
      case "latitude":
        const latitudeRegex = /^-?([1-8]?[0-9]|90)(\.[0-9]+)?$/;
        stationState[name + "Status"] =
          value === ""
            ? "unchecked"
            : latitudeRegex.test(value.trim())
            ? "success"
            : "error";
        break;
      case "longitude":
        const longitudeRegex = /^-?([1-9]?[0-9]|1[0-7][0-9]|180)(\.[0-9]+)?$/;
        stationState[name + "Status"] =
          value === ""
            ? "unchecked"
            : longitudeRegex.test(value.trim())
            ? "success"
            : "error";
        break;
      case "stationRange":
        stationState[name + "Status"] = value === "" ? "unchecked" : "success";
        break;
      case "stationDistrict":
        stationState[name + "Status"] = value === "" ? "unchecked" : "success";
        break;
      case "stationCity":
        stationState[name + "Status"] = value === "" ? "unchecked" : "success";
        break;
      default:
        break;
    }

    stationState[name] = value;
    setStationDetails(stationState);
  };

  const handleFormSubmit = async () => {
    const stationData = {
      station_id: stationDetails.stationId,
      station_location: stationDetails.stationLocation,
      latitude: stationDetails.latitude,
      longitude: stationDetails.longitude,
      range: stationDetails.stationRange,
      district: stationDetails.stationDistrict,
      city: stationDetails.stationCity,
    };

    if (oneStationData) {
      try {
        const result = await putData(`api/station/${oneStationData.id}`, stationData);
        navigate("/station-Master");
        handleClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      getStationData();
    } else {
      try {
        const result = await postData("api/station", stationData);
        navigate("/station-Master");
        handleClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }

    setStationDetails({
      stationId: "",
      stationLocation: "",
      latitude: "",
      longitude: "",
      stationRange: "",
      stationDistrict: "",
      stationCity: "",
      stationIdStatus: "unchecked",
      stationLocationStatus: "unchecked",
      latitudeStatus: "unchecked",
      longitudeStatus: "unchecked",
      stationRangeStatus: "unchecked",
      stationDistrictStatus: "unchecked",
      stationCityStatus: "unchecked",
    });

    handleClose();
  };

  const isFormValid = () => {
    return (
      stationDetails.stationIdStatus === "success" &&
      stationDetails.stationLocationStatus === "success" &&
      stationDetails.latitudeStatus === "success" &&
      stationDetails.longitudeStatus === "success" &&
      stationDetails.stationRangeStatus === "success" &&
      stationDetails.stationDistrictStatus === "success" &&
      stationDetails.stationCityStatus === "success" &&
      stationDetails.stationId.trim() !== "" &&
      stationDetails.stationLocation.trim() !== "" &&
      stationDetails.latitude.trim() !== "" &&
      stationDetails.longitude.trim() !== "" &&
      stationDetails.stationRange.trim() !== "" &&
      stationDetails.stationDistrict.trim() !== "" &&
      stationDetails.stationCity.trim() !== ""
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Station Detail Form</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {/* Station ID Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Station ID*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="stationId"
                value={stationDetails.stationId}
                onChange={handleStationDataChange}
                error={stationDetails.stationIdStatus === "error"}
                success={stationDetails.stationIdStatus === "success"}
                placeholder="Enter station ID"
              />
              {stationDetails.stationIdStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Station ID must be alphanumeric</FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          {/* Station Location Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Station Name*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="stationLocation"
                value={stationDetails.stationLocation}
                onChange={handleStationDataChange}
                error={stationDetails.stationLocationStatus === "error"}
                success={stationDetails.stationLocationStatus === "success"}
                placeholder="Enter station location"
              />
            </Stack>
          </Grid>

          {/* Station Range Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Station Range*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="stationRange"
                value={stationDetails.stationRange}
                onChange={handleStationDataChange}
                error={stationDetails.stationRangeStatus === "error"}
                success={stationDetails.stationRangeStatus === "success"}
                placeholder="Enter station range"
              />
            </Stack>
          </Grid>

          {/* Station District Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Station District*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="stationDistrict"
                value={stationDetails.stationDistrict}
                onChange={handleStationDataChange}
                error={stationDetails.stationDistrictStatus === "error"}
                success={stationDetails.stationDistrictStatus === "success"}
                placeholder="Enter station district"
              />
            </Stack>
          </Grid>

          {/* Station City Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Station City*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="stationCity"
                value={stationDetails.stationCity}
                onChange={handleStationDataChange}
                error={stationDetails.stationCityStatus === "error"}
                success={stationDetails.stationCityStatus === "success"}
                placeholder="Enter station city"
              />
            </Stack>
          </Grid>

          {/* Latitude Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Latitude*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="latitude"
                value={stationDetails.latitude}
                onChange={handleStationDataChange}
                error={stationDetails.latitudeStatus === "error"}
                success={stationDetails.latitudeStatus === "success"}
                placeholder="Enter latitude"
              />
              {stationDetails.latitudeStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Enter a valid latitude (-90 to 90)</FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          {/* Longitude Field */}
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography component="label" variant="caption" fontWeight="bold">
                Longitude*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="longitude"
                value={stationDetails.longitude}
                onChange={handleStationDataChange}
                error={stationDetails.longitudeStatus === "error"}
                success={stationDetails.longitudeStatus === "success"}
                placeholder="Enter longitude"
              />
              {stationDetails.longitudeStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Enter a valid longitude (-180 to 180)</FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          

         {stationDetails.latitude && stationDetails.longitude && <LocationData latitude={stationDetails.latitude} longitude={stationDetails.longitude}/>}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12}>
          <MaterialButton color="dark" onClick={handleClose}>Cancel</MaterialButton>
        </Grid>
        <Grid item xs={12}>
          <MaterialButton
            variant="gradient"
            color="dark"
            onClick={handleFormSubmit}
            disabled={oneStationData ? false : !isFormValid()}
          >
            Submit
          </MaterialButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default StationDetailFrom;
