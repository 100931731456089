import React from 'react'
import DashboardLayout from '../../../../layoutcontainers/DashboardLayout'
import SummeryTable from '../crimMapingView/SummeryTable'
import AbstractTable from './AbstractTable'

function CrimeSummery() {
  return (
    <div>
        <DashboardLayout>
            {/* <SummeryTable/> */}
            <AbstractTable/>
        </DashboardLayout>
    </div>
  )
}

export default CrimeSummery