import React, { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialSelect from "../../../../components/MasterSelect";
import LocationSearch from "./LocationSearch";
import CrimeFilters from "./CrimeFilters";
import MaterialButton from "../../../../components/MaterialButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SummeryTable from "./SummeryTable";
import CloseIcon from "@mui/icons-material/Close";
import StationFilter from "./StationFilter"
import { BASE_URL, fetchData } from "../../../../BaseUrl";
import AbstractTable from "../crimeSummery/AbstractTable";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DynamicFilters({
  locationData,
  onLocationChange,
  onCrimeFilterChange,
  getCrimeData,
}) {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [crimeFromDate, setCrimeFromDate] = useState("");
  const [crimeToDate, setCrimeToDate] = useState("");
  const [crimeType, setCrimeType] = useState("");
  const [resources, setResources] = useState({
    filterOptions: [
      // { value: "Location Filter", label: "Location Filter" },
      { value: "Crime Filter", label: "Crime Filter" },
      { value: "Station Filter", label: "Station Filter" },
    ],
  });

  const [stationName, setStationName] = useState("");
  const [jurisdictionName, setjurisdictionName] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Handle filter change
  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption || null); // Storing the entire option object
  };

  const reset = () => {
    getCrimeData();
    onCrimeFilterChange("");
    onLocationChange("");
    setSelectedFilter(null);
  };

  const handleSubmit = async () => {
    const data = {
      crimeType: crimeType ? crimeType?.value : "",
      crimeFromDate: crimeFromDate,
      crimeToDate: crimeToDate,
    };
  
    try {
      const crimeType = encodeURIComponent(data.crimeType);
      const startDate = encodeURIComponent(data.crimeFromDate);
      const endDate = encodeURIComponent(data.crimeToDate);
  
      // Make API request to fetch the Excel data
      const response = await fetch(
        `${BASE_URL}api/exportIncidents?crimeType=${crimeType}&exportFormat=excel&startDate=${startDate}&endDate=${endDate}`,
        { method: 'GET' }
      );
  
      if (response.ok) {
        // Ensure the response is binary (Blob)
        const blob = await response.blob();
  
        console.log('Blob size:', blob.size);
        console.log('Blob type:', blob.type);
  
        if (blob.size > 0) {
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = "incidents_report.xlsx"; // Correct file extension
          link.click(); // Trigger the download
  
          // Clean up the URL object
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Empty response or invalid file data received");
        }
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md" // Allows the dialog to be bigger
        fullWidth={true} // Makes the dialog width 100% of the screen
        sx={{ width: "100%", height:"90vh" }}
      >
        <DialogTitle>Crime Report Summary</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText sx={{p:2}} id="alert-dialog-slide-description">
              <AbstractTable />
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          
          <MaterialButton
            variant="gradient"
            color="dark"
            style={{ maxWidth: "100px", minWidth: "100px" }}
            onClick={handleClose}
          >
           OK
          </MaterialButton>
        </DialogActions> */}
      </Dialog>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Select Filter*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="selectedFilter"
            value={selectedFilter} // Pass the whole object
            onChange={handleFilterChange}
            options={resources?.filterOptions.map((value) => ({
              value: value.value,
              label: value.label,
            }))}
            isClearable
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          container
          spacing={2}
          sx={{
            mt: 3.6,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <MaterialBox lineHeight={0}>
            <MaterialButton variant="gradient" color="dark" onClick={reset}>
              Reset
            </MaterialButton>
          </MaterialBox>

          <MaterialBox lineHeight={0}>
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={handleSubmit}
            >
              Export as csv
            </MaterialButton>
          </MaterialBox>

          <MaterialBox lineHeight={0}>
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={handleClickOpen}
            >
              Abstract
            </MaterialButton>
          </MaterialBox>
        </Grid>

        <Grid item xs={12} sm={12}>
          {/* Render fields based on the selected filter */}
          {selectedFilter?.value === "Crime Filter" && (
            <CrimeFilters
              onCrimeFilterChange={onCrimeFilterChange}
              crimeFromDate={crimeFromDate}
              setCrimeFromDate={setCrimeFromDate}
              crimeToDate={crimeToDate}
              setCrimeToDate={setCrimeToDate}
              crimeType={crimeType}
              setCrimeType={setCrimeType}
            />
          )}
          {selectedFilter?.value === "Location Filter" && (
            <LocationSearch
              locationData={locationData}
              onLocationChange={onLocationChange}
            />
          )}
          {selectedFilter?.value === "Station Filter" && (
            <StationFilter
            onCrimeFilterChange={onCrimeFilterChange}
            stationName={stationName}
            jurisdictionName={jurisdictionName}
            setStationName={setStationName}
            setjurisdictionName={setjurisdictionName}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DynamicFilters;
