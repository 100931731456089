// CrimeMaster.js
import React, { useState } from "react";
import DashboardLayout from "../../../../layoutcontainers/DashboardLayout";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialButton from "../../../../components/MaterialButton";
import CrimeTypeDataGrid from "./CrimeTypeDataGrid";
import CrimeTypeDialog from "./CrimeTypeDialog"; // import the new Dialog component
import { BASE_URL, postData } from "../../../../BaseUrl";

function CrimeMaster() {
  const [addCrimeType, setAddCrimeType] = useState({
    crimeIcon: "",
      crimeType: "",
      head:'',
      crimeIconStatus: "unchecked",
      crimeTypeStatus: "unchecked",
      headStatus: "unchecked",
  });
  const [crimeIcon, setCrimeIcon] = useState("")
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [result, setResult] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
// console.log("crimeIcon",crimeIcon)
  const handleSubmit = async (chip1,chip2) => {
    console.log("chip1,chip2", chip1,chip2)

    const crimeData = {
      name: addCrimeType.crimeType,
      logo: crimeIcon.url,
      head:addCrimeType.head,
      subhead:chip1,
      crime_status:chip2
    };
    // console.log("crimeData", crimeData)
    try {
      const result = await postData('api/crimeType', crimeData);
      console.log("result", result)
      setSelectedIndex(null)
      setResult(true)
      handleClose();
    } catch (err) {
      console.error('Error posting data:', err);
    }
    
    setAddCrimeType({
      crimeIcon: "",
      crimeType: "",
      head:'',
      crimeIconStatus: "unchecked",
      crimeTypeStatus: "unchecked",
      headStatus: "unchecked",
    });
  };

  return (
    <DashboardLayout>
      <MaterialBox>
        <MaterialButton variant="gradient" color="dark" onClick={handleClickOpen}>
          Add +
        </MaterialButton>
      </MaterialBox>
      <CrimeTypeDataGrid  />
      <CrimeTypeDialog
        open={open}
        handleClose={handleClose}
        addCrimeType={addCrimeType}
        setAddCrimeType={setAddCrimeType}
        image={image}
        setImage={setImage}
        error={error}
        setError={setError}
        handleSubmit={handleSubmit}
        crimeIcon={crimeIcon}
        setCrimeIcon={setCrimeIcon}
        selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} result={result}
      />
    </DashboardLayout>
  );
}

export default CrimeMaster;
