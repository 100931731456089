import React, { useEffect, useState } from "react";
import MaterialDataGrid from "../../../../components/MaterialDataGrid";
import {
  fetchData,
  fetchDataById,
  deleteData,
} from "../../../../BaseUrl";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import UserDataForm from "./UserDataForm";

const UserDataGrid = (result) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [oneUserData, setOneUserData] = useState(null); 
  const [dialogOpen, setDialogOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false); 
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    getUserData();
  }, [result]);

  const getUserData = async () => {
    try {
      const data = await fetchData("api/user");
      setUserData(data);
    } catch (error) {
      console.error("Error fetching crime data", error);
    } finally {
      setLoading(false);
    }
  };

  const onView = async (id) => {
    try {
      const item = await fetchDataById(`api/crimeType/${id}`);
    } catch (error) {
      console.error(`Error fetching item with ID ${id}:`, error);
    }
  };

  const onEdit = async (id) => {
    try {
      const item = await fetchDataById(`api/user/${id}`);
      // console.log("item", item)
      setOneUserData(item);
      setDialogOpen(true); 
      getUserData()
    } catch (error) {
      console.error(`Error fetching item with ID ${id}:`, error);
    }
  };

  const onDelete = (id) => {
    setDeleteId(id); 
    setOpenDialog(true); 
  };

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "userName", headerName: "User Name", width: 200 },
    { field: "userId", headerName: "User Id", width: 200, },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 200 },
    { field: "role", headerName: "Role", width: 200 },
    { field: "station_location", headerName: "station", width: 200 },
    { field: "actions", headerName: "Actions", width: 200 },
  ];

  const handleDialogConfirm = async () => {
    if (deleteId) {
      try {
        const result = await deleteData(`api/user/${deleteId}`);
        getUserData();
        setDeleteId(null);
      } catch (error) {
        console.error(`Error deleting item with ID ${deleteId}:`, error);
      }
    }
    setOpenDialog(false); 
  };

 
  const handleDialogCancel = () => {
    setDeleteId(null); 
    setOpenDialog(false); 
  };


  return (
    <div style={{ height: 400, width: "100%" }}>
      <MaterialDataGrid
        rows={userData}
        columns={columns}
        loading={loading}
        // checkboxSelection={true}
        onRowClick={(params) => console.log(params.row)}
        customHeight={400}
        isSelectable={true}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
      />
       {dialogOpen && oneUserData && (
         <UserDataForm
         oneUserData={oneUserData}
         open={dialogOpen}
         setOpen={setDialogOpen}
         getUserData={getUserData}
       />
      )}

      <ConfirmationDialog
        open={openDialog} 
        showCancel={true} 
        showConfirm={true} 
        title="Confirm Deletion"
        message="Are you sure you want to delete this crime type?" 
        onConfirm={handleDialogConfirm} 
        onCancel={handleDialogCancel} 
      />
    </div>
  );
};

export default UserDataGrid;
