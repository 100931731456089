import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialSelect from "../../../../components/MasterSelect"; // Assuming this component is wrapped around Select
import MaterialInput from "../../../../components/MaterialInput";
import { postData, BASE_URL, fetchData } from "../../../../BaseUrl";
import MaterialButton from "../../../../components/MaterialButton";

function CrimeFilters({ onCrimeFilterChange,crimeFromDate ,setCrimeFromDate,crimeToDate,setCrimeToDate,crimeType,setCrimeType}) {
  // Accept onChange prop
 
 const [error, setError] = useState("");

 const [resources, setResources] = useState({
  crimeType: [
    { value: "", label: "" },
  ],
});

useEffect(() => {
  getCrimeType()
}, [])

const getCrimeType = async () => {
  try {
    const result = await fetchData("api/crimeType");
    // console.log("result", result);

    const crimeTypeData = result.map(crimeType => ({
      value: crimeType.name,     
      label: crimeType.name,    
    }));
    setResources(prevState => ({
      ...prevState,
      crimeType: crimeTypeData, // Set the crimeType property of the state
    }));
  } catch (error) {
    console.error("Error fetching crime types:", error);
  }
};

const [user, setuser] = useState([]);

useEffect(() => {
  getUserId();
}, []);

const getUserId = async () => {
  try {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found.");
      return;
    }

    const response = await fetch(`${BASE_URL}api/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    // console.log("resultuserId", result);

    if (result && result.userId) {
      // console.log("result",result)
      setuser(result);

}  } catch (error) {
    console.error("Error fetching userId:", error);
  }
};

  const handleCrimeTypeChange = (selectedOption) => {
    setCrimeType(selectedOption || null);
    // Pass filter data back to parent when it changes
  };

  const handleCrimeFromDateChange = (event) => {
    setCrimeFromDate(event.target.value);
  };

  const handleCrimeToDateChange = (event) => {
    setCrimeToDate(event.target.value);
  };

  const handleSubmit = async () => {
    if (new Date(crimeToDate) < new Date(crimeFromDate)) {
      setError("To Date cannot be earlier than From Date");
      return;
    }

    setError("");

    const data = {
      crimeType: crimeType ? crimeType?.value : "",
      crimeFromDate: crimeFromDate,
      crimeToDate: crimeToDate,
    };

    try {
      const crimeType = encodeURIComponent(data.crimeType);
      const startDate = encodeURIComponent(data.crimeFromDate);
      const endDate = encodeURIComponent(data.crimeToDate);

      const response = await fetchData(
        `${BASE_URL}api/crimeFilter/${user.userId}?type=${crimeType}&startDate=${startDate}&endDate=${endDate}`
      );
      onCrimeFilterChange(response);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Crime type*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="crimeType"
            value={crimeType}
            customStyles={{ optionPadding: 20 }}
            onChange={handleCrimeTypeChange}
            options={resources?.crimeType.map((value) => ({
              value: value.value,
              label: value.label,
            }))}
            isClearable
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              From Date*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="crimeFromDate"
            type="date"
            value={crimeFromDate}
            onChange={handleCrimeFromDateChange}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              To Date*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="crimeToDate"
            type="date"
            value={crimeToDate}
            onChange={handleCrimeToDateChange}
          />
          {error && (
            <MaterialBox sx={{ color: "error.main", mt: 2 }}>
              <MaterialTypography variant="body2">{error}</MaterialTypography>
            </MaterialBox>
          )}
        </Grid>

        <Grid item xs={12} sm={2} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={handleSubmit}
            >
              Submit
            </MaterialButton>
          </MaterialBox>
        </Grid>
      </Grid>
    </div>
  );
}

export default CrimeFilters;
