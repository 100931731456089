import React, { useEffect, useState } from "react";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialButton from "../../../../components/MaterialButton";
import { fetchData, postData } from "../../../../BaseUrl";
import StationDetailFrom from "./StationDetailFrom";
import DashboardLayout from "../../../../layoutcontainers/DashboardLayout";
import StationDataGrid from "./StationDataGrid";
import StationFlowChart from "./StationFlowChart";
import bgImage from "../../../../assest/bg-image.png";

const StationMaster = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [stationData, setstationData] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  useEffect(() => {
    getStationData();
  }, []);

  const getStationData = async () => {
    try {
      const data = await fetchData("api/station");
      console.log("datazz", data.stations)
      setstationData(data.stations);
      
    } catch (error) {
      console.error("Error fetching crime data", error);
    }
  };
 

  return (
    <MaterialBox>
      <DashboardLayout>
      <div sx={{
            position: "relative",
          }}>
             <img
            src={bgImage}
            alt="background"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.2,
            }}
          />
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <MaterialButton
            variant="gradient"
            color="dark"
            onClick={handleClickOpen}
          >
            Add +
          </MaterialButton>

          <MaterialButton
            variant="gradient"
            color="dark"
            onClick={handleClickOpen1}
          >
            View Chart
          </MaterialButton>
        </div>

        <StationFlowChart stationData={stationData} open={open1} setOpen={setOpen1}/>
        <StationDetailFrom open={open} setOpen={setOpen} />
        <StationDataGrid />
        </div>
      </DashboardLayout>
    </MaterialBox>
  );
};

export default StationMaster;
