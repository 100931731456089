import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialSelect from "../../../../components/MasterSelect"; // Assuming this component is wrapped around Select
import MaterialInput from "../../../../components/MaterialInput";
import {
  BASE_URL,
  Getdistrictofcity,
  Getrangeofdistrict,
  CCTVFilterbasedonrole,
  GetstationRange,
} from "../../../../BaseUrl";
import MaterialButton from "../../../../components/MaterialButton";

function StationFilter({
  onCrimeFilterChange,
  stationName,
  jurisdictionName,
  setStationName,
  setjurisdictionName,
}) {
  const [user, setuser] = useState([]);

  const [lacationdata, setlacationdata] = useState([]);

  const [openlocation, setopenlocation] = useState(false);

  const [stationselect, setstationselect] = useState([]);
  const [rangeselect, setrangeselect] = useState([]);
  const [districtselect, setdistrictselect] = useState([]);
  const [cityselect, setcityselect] = useState([]);
  const [filtershow, setfiltershow] = useState(0);

  const [crimeReport, setCrimeReport] = useState({
    city: "",
    District: "",
    range: "",
    selectedvaluestation: "",
  });

  const [crimeFromDate, setCrimeFromDate] = useState("");
  const [crimeToDate, setCrimeToDate] = useState("");
  const [error, setError] = useState("");

  const handleCrimeFromDateChange = (event) => {
    setCrimeFromDate(event.target.value);
  };

  const handleCrimeToDateChange = (event) => {
    const newToDate = event.target.value;
    setCrimeToDate(newToDate);
  
    // Validate the dates after updating the state
    if (new Date(newToDate) < new Date(crimeFromDate)) {
      setError("To Date cannot be earlier than From Date");
    } else {
      setError(""); // Clear the error if the condition is valid
    }
  };

  useEffect(() => {
    getdatafilter();
  }, [user]);

  useEffect(() => {
    getalldistrictbasedoncity(crimeReport.city);
  }, [crimeReport.city]);
  useEffect(() => {
    getallRangebasedonDistrict(crimeReport.District);
  }, [crimeReport.District]);
  useEffect(() => {
    getallstationbasedonRange(crimeReport.range);
  }, [crimeReport.range]);

  useEffect(() => {
    getUserId();
  }, []);

  const getUserId = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found.");
        return;
      }

      const response = await fetch(`${BASE_URL}api/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      // console.log("resultuserId", result);

      if (result && result.userId) {
        // console.log("result",result)
        setuser(result);
        if (result.role === "DC") {
          // console.log("filtershow",filtershow)
          setfiltershow(1);
          setcityselect([
            { value: result.station_location, label: result.station_location },
          ]);
          setCrimeReport({
            ...crimeReport,
            city: {
              value: result.station_location,
              label: result.station_location,
            },
          });
          getdatafilter();
          // setopenlocation(true)
        } else if (result.role === "Commissioner") {
          setfiltershow(2);
          setdistrictselect([
            { value: result.station_location, label: result.station_location },
          ]);
          setCrimeReport({
            ...crimeReport,
            District: {
              value: result.station_location,
              label: result.station_location,
            },
          });
          getdatafilter();
        } else if (result.role === "AC") {
          setfiltershow(3);
          setrangeselect([
            { value: result.station_location, label: result.station_location },
          ]);
          setCrimeReport({
            ...crimeReport,
            range: {
              value: result.station_location,
              label: result.station_location,
            },
          });
          getdatafilter();
        } else {
          setfiltershow(4);
          setstationselect([
            { value: result.station_location, label: result.station_location },
          ]);
          setCrimeReport({
            ...crimeReport,
            selectedvaluestation: {
              value: result.station_location,
              label: result.station_location,
            },
          });
          getdatafilter();
        }
      } else {
        console.log("No userId found in the response.");
      }
    } catch (error) {
      console.error("Error fetching userId:", error);
    }
  };

  const getalldistrictbasedoncity = async (value) => {
    // console.log("value",value)
    try {
      const result = await Getdistrictofcity(
        `/api/stations/city?filterValue=${value.label}`
      );

      //  console.log("result",result)
      if (result.length > 0) {
        setdistrictselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallRangebasedonDistrict = async (value) => {
    // console.log("value",value)
    try {
      const result = await Getrangeofdistrict(
        `/api/stations/district?filterValue=${value.label}`
      );

      //  console.log("result",result)
      if (result.length > 0) {
        setrangeselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallstationbasedonRange = async (value) => {
    // console.log("value",value)
    try {
      const result = await GetstationRange(
        `/api/stations/range?filterValue=${value.label}`
      );

      //  console.log("result",result)
      if (result.length > 0) {
        setstationselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const handleCrimeReportDataChange = (event, selectEvent) => {
    const changeAllowed = true;
    let crimeState = { ...crimeReport };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "District") {
        crimeState[selectEvent.name] = event;
        getallRangebasedonDistrict();
      } else if (selectEvent.name === "range") {
        crimeState[selectEvent.name] = event;
      } else if (selectEvent.name === "selectedvaluestation") {
        crimeState[selectEvent.name] = event;
      }
      setCrimeReport(crimeState);
    }
  };

  const getdatafilter = async () => {
    try {
      console.log("user.userId", user.userId);
      const result = await CCTVFilterbasedonrole(
        `api/crimeFilter/${user.userId}`
      );
      // http://localhost:3000/api/cctvs/statistics-role/CPD-00789-2024?range=Tambaram&city=Tambaram
      console.log("resultfilter from new", result);
      console.log("Crime data fetched", result);
      let arr = [];
      arr.push(result);
      setlacationdata(arr);
      setopenlocation(true);
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const clickgetdatafilter = async (querystring) => {
    try {
      const result = await CCTVFilterbasedonrole(
        `api/crimeFilter/${user.userId}${querystring}`
      );
      onCrimeFilterChange(result);
      console.log("resultfilter from new", result);
      console.log("Crime data fetched", result);
      let arr = [];
      arr.push(result);
      setlacationdata(arr);
      setopenlocation(true);
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const Resetfilter=()=>
    {
      getdatafilter()
      if(user.role=="DC")
        {
          // console.log("filtershow",filtershow)
          setfiltershow(1)
          setcityselect([{ value: user.station_location, label: user.station_location }])
          setCrimeReport({
            ...crimeReport,
            city:{ value: user.station_location, label: user.station_location },
            District:"",
            range:"",
            selectedvaluestation:""
          })
          getdatafilter()
         
   
        }
        else if(user.role=="Commissioner")
        {
          setfiltershow(2)
          setdistrictselect([{ value: user.station_location, label: user.station_location }])
          setCrimeReport({
            ...crimeReport,
            District:{ value: user.station_location, label: user.station_location },
            range:"",
            selectedvaluestation:""
          })
   
        }
        else if(user.role=="AC")
        {
          setfiltershow(3)
          setrangeselect([{ user: user.station_location, label: user.station_location }])
          setCrimeReport({
            ...crimeReport,
            range:{ value: user.station_location, label: user.station_location },
            selectedvaluestation:""
          })
         
        }
        else
        {
          setfiltershow(4)
         
        }
     
     
    }

  const submitFilter = (station_location, range, district, city) => {
    // console.log("dasf",type, status, station_location, range, district, city)
    let queryParts = [];

    if (district) queryParts.push(`district=${district}`);
    // if (type) queryParts.push(`type=${type}`);
    // if (status) queryParts.push(`status=${status}`);
    if (station_location)
      queryParts.push(`station_location=${station_location}`);
    if (range) queryParts.push(`range=${range}`);
    if (city) queryParts.push(`city=${city}`);
    if (crimeFromDate) queryParts.push(`startDate=${crimeFromDate}`);
    if (crimeToDate) queryParts.push(`endDate=${crimeToDate}`);
  
    let queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";

    console.log(queryString);
    clickgetdatafilter(queryString);
  };
  return (
    <div>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        {filtershow === 1 && (
          <>
            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  City*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="city"
                value={crimeReport.city}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={cityselect?.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={cityselect}
                // error={crimeReport.cityStatus === "error" ? true : false}
                // success={crimeReport.cityStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.cityStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  District*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="District"
                value={crimeReport.District}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={districtselect}
                // error={crimeReport.DistrictStatus === "error" ? true : false}
                // success={crimeReport.DistrictStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.DistrictStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Range*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="range"
                value={crimeReport.range}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={rangeselect}
                // error={crimeReport.rangeStatus === "error" ? true : false}
                // success={crimeReport.rangeStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.rangeStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Station*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="selectedvaluestation"
                value={crimeReport.selectedvaluestation}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={stationselect}
                // error={crimeReport.selectedvaluestationStatus === "error" ? true : false}
                // success={crimeReport.selectedvaluestationStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.selectedvaluestationStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>
          </>
        )}
        {filtershow === 2 && (
          <>
            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  District*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="District"
                value={crimeReport.District}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={districtselect}
                // error={crimeReport.DistrictStatus === "error" ? true : false}
                // success={crimeReport.DistrictStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.DistrictStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Range*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="range"
                value={crimeReport.range}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={rangeselect}
                // error={crimeReport.rangeStatus === "error" ? true : false}
                // success={crimeReport.rangeStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.rangeStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Station*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="selectedvaluestation"
                value={crimeReport.selectedvaluestation}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={stationselect}
                // error={crimeReport.selectedvaluestationStatus === "error" ? true : false}
                // success={crimeReport.selectedvaluestationStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.selectedvaluestationStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>
          </>
        )}
        {filtershow === 3 && (
          <>
            <Grid item xs={12} sm={6}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Range*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="range"
                value={crimeReport.range}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={rangeselect}
                // error={crimeReport.rangeStatus === "error" ? true : false}
                // success={crimeReport.rangeStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.rangeStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>

            <Grid item xs={12} sm={6}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Station*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="selectedvaluestation"
                value={crimeReport.selectedvaluestation}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={stationselect}
                // error={crimeReport.selectedvaluestationStatus === "error" ? true : false}
                // success={crimeReport.selectedvaluestationStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.selectedvaluestationStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>
          </>
        )}
        {filtershow === 4 && (
          <>
            <Grid item xs={12} sm={3}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Station*
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
                name="selectedvaluestation"
                value={crimeReport.selectedvaluestation}
                // defaultValue={{ label: "Mr." }}
                customStyles={{ optionPadding: 20 }}
                onChange={handleCrimeReportDataChange}
                isSearchable={false}
                // options={resources?.crimeType.map((value) => {
                //   return {
                //     value: value.value,
                //     label: value.label,
                //   };
                // })}
                options={stationselect}
                // error={crimeReport.selectedvaluestationStatus === "error" ? true : false}
                // success={crimeReport.selectedvaluestationStatus === "success" ? true : false}
                isClearable
              />
              {/* {crimeReport.selectedvaluestationStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )} */}
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              From Date*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="crimeFromDate"
            type="date"
            value={crimeFromDate}
            onChange={handleCrimeFromDateChange}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              To Date*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="crimeToDate"
            type="date"
            value={crimeToDate}
            onChange={handleCrimeToDateChange}
          />
          {error && (
            <MaterialBox sx={{ color: "error.main", mt: 2 }}>
              <MaterialTypography variant="body2">{error}</MaterialTypography>
            </MaterialBox>
          )}
        </Grid>
        <Grid item xs={12} sm={1.5} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={() =>
                submitFilter(
                  crimeReport.selectedvaluestation.label,
                  crimeReport.range.label,
                  crimeReport.District.label,
                  crimeReport.city.label
                )
              }
            >
              Submit
            </MaterialButton>
          </MaterialBox>
        </Grid>
        <Grid item xs={12} sm={1.5} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={Resetfilter}
            >
              Reset
            </MaterialButton>
          </MaterialBox>
        </Grid>
      </Grid>
    </div>
  );
}

export default StationFilter;
