import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialInput from "../../../../components/MaterialInput";
import MaterialButton from "../../../../components/MaterialButton";

function LocationSearch({ locationData, onLocationChange }) {
  const [locationName, setLocationName] = useState(locationData.locationName); 
  const [latitude, setLatitude] = useState(locationData.latitude);
  const [longitude, setLongitude] = useState(locationData.longitude); 
  const [error, setError] = useState("");

  // Handlers for each input field
  const handleLocationNameChange = (event) => {
    setLocationName(event.target.value);
  };

  const handleLatitudeChange = (event) => {
    setLatitude(event.target.value);
  };

  const handleLongitudeChange = (event) => {
    setLongitude(event.target.value);
  };

  // Submit handler
  const handleSubmit = () => {
    // Validate Latitude and Longitude
    if (latitude < -90 || latitude > 90) {
      setError("Latitude must be between -90 and 90");
      return;
    }

    if (longitude < -180 || longitude > 180) {
      setError("Longitude must be between -180 and 180");
      return;
    }

    setError(""); // Clear any previous errors

    const data = {
      locationName: locationName,
      latitude: latitude,
      longitude: longitude,
    };
    onLocationChange(data);
  };

  useEffect(() => {
    setLocationName(locationData.locationName);
    setLatitude(locationData.latitude);
    setLongitude(locationData.longitude);
  }, [locationData]);

  return (
    <div>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography component="label" variant="caption" fontWeight="bold">
              Location Name*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="locationName"
            value={locationName}
            onChange={handleLocationNameChange}
            placeholder="Enter location"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography component="label" variant="caption" fontWeight="bold">
              Latitude*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="latitude"
            value={latitude}
            onChange={handleLatitudeChange}
            placeholder="Enter latitude"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography component="label" variant="caption" fontWeight="bold">
              Longitude*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="longitude"
            value={longitude}
            onChange={handleLongitudeChange}
            placeholder="Enter longitude"
          />
          {error && (
            <MaterialBox sx={{ color: "error.main", mt: 2 }}>
              <MaterialTypography variant="body2">{error}</MaterialTypography>
            </MaterialBox>
          )}
        </Grid>
        <Grid item xs={12} sm={2} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton variant="gradient" color="dark" onClick={handleSubmit}>
              Submit
            </MaterialButton>
          </MaterialBox>
        </Grid>
      </Grid>
    </div>
  );
}

export default LocationSearch;
