import React, { useEffect, useState } from "react";
import MaterialButton from "../../../../components/MaterialButton";
import MaterialBox from "../../../../components/MaterialBox";
import { fetchData } from "../../../../BaseUrl";

const ImageList = ({
  setCrimeIcon,
  oneCrimeData,
  setSelectedIndex,
  selectedIndex,
  result,
}) => {
  const [selectedImage, setSelectedImage] = useState(false);
  const color = "000000";
 const iconUrls = [
    "https://keelis.com/Cicons/icon-.2.png",
    "https://keelis.com/Cicons/icon-0.3.png",
    "https://keelis.com/Cicons/iCON-4.0.png",
    "https://keelis.com/Cicons/icon-5.1.png",
    "https://keelis.com/Cicons/iCON-1%205.png",
    "https://keelis.com/Cicons/icon-3%203.png",

]

  const [crimeData, setCrimeData] = useState([]);
  useEffect(() => {
    getCrimeData();
  }, []);

  useEffect(() => {
    getCrimeData();
  }, [result]);

  const getCrimeData = async () => {
    try {
      const data = await fetchData("api/crimeType");
      setCrimeData(data);
    } catch (error) {
      console.error("Error fetching crime data", error);
    }
  };

  // Extract all logo_urls from the crimeData
  const existingUrls = crimeData.map((crime) => crime.logo_url);

  // Filter out the iconUrls that are already present in crimeData
  const filteredIconUrls = iconUrls.filter(
    (url) => !existingUrls.includes(url)
  );

  // Handle image click to set the selected index
  const handleClick = (index, url) => {
    setSelectedIndex(index);
    const payload = { url };
    setCrimeIcon(payload);
  };

  // Handle remove button click to reset `oneCrimeData` (set it to null or reset the state)
  const handleRemoveImage = () => {
    setSelectedImage(true); // This will reset the `oneCrimeData` in the parent component
  };

  return (
    <div style={{ width: "100%" }}>
      {oneCrimeData && selectedImage === false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <img src={oneCrimeData.logo_url} alt="logo_url" style={{height:"100px", width:"100px"}} />
          <br />
          <MaterialButton
            variant="gradient"
            color="dark"
            style={{
              maxWidth: "100px",
              minWidth: "100px",
              marginTop: "10px",
            }}
            onClick={handleRemoveImage}
          >
            Remove
          </MaterialButton>
        </div>
      ) : (
        filteredIconUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`Icon ${index + 1}`}
            style={{
              margin: "10px",
              cursor: "pointer",
              height:"100px", width:"100px",
              border: selectedIndex === index ? "3px solid red" : "none",
              padding: selectedIndex === index ? "3px" : "0",
            }}
            onClick={() => handleClick(index, url)}
          />
        ))
      )}
    </div>
  );
};

export default ImageList;
