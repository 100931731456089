import React from "react";
import Grid from "@mui/material/Grid2";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialInput from "../../../../components/MaterialInput";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import LocationData from "./LocationData";
import MaterialSelect from "../../../../components/MasterSelect";

const CrimeForm = ({
  station,
  crimeReport,
  handleCrimeReportDataChange,
  handleRedZoneChange,cityselect, districtselect,rangeselect, stationselect
}) => {
  // console.log("station", station)
  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12, sm: 12 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Incident Id*
          </MaterialTypography>
        </MaterialBox>
        <Stack sx={{ width: "50%" }}>
          <MaterialInput
          disabled
            name="incidentId"
            placeholder="eg. 5634873"
            value={crimeReport.incidentId}
            onChange={handleCrimeReportDataChange}
            error={crimeReport.incidentIdStatus === "error" ? true : false}
            success={crimeReport.incidentIdStatus === "success" ? true : false}
          />
          {crimeReport.incidentIdStatus === "error" && (
            <FormControl error>
              <FormHelperText>Incident id should be in numbers</FormHelperText>
            </FormControl>
          )}
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Location latitude*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="locationlatitude"
          placeholder="eg. 12.9249"
          value={crimeReport.locationlatitude}
          onChange={handleCrimeReportDataChange}
          error={crimeReport.locationlatitudeStatus === "error" ? true : false}
          success={crimeReport.locationlatitudeStatus === "success" ? true : false}
        />
        {crimeReport.locationlatitudeStatus === "error" && (
          <FormControl error>
            <FormHelperText>Location id should be in numbers</FormHelperText>
          </FormControl>
        )}
      </Grid>
      
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Location longitude*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="locationlongitude"
          placeholder="eg. 80.1000"
          value={crimeReport.locationlongitude}
          onChange={handleCrimeReportDataChange}
          error={crimeReport.locationlongitudeStatus === "error" ? true : false}
          success={crimeReport.locationlongitudeStatus === "success" ? true : false}
        />
        {crimeReport.locationlongitudeStatus === "error" && (
          <FormControl error>
            <FormHelperText>Location id should be in numbers</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 12 }}>
        <LocationData latitude={crimeReport.locationlatitude} longitude={crimeReport.locationlongitude}/>
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Date*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="crimeDate"
          type="date"
          value={crimeReport.crimeDate}
          onChange={handleCrimeReportDataChange}
          error={crimeReport.crimeDateStatus === "error" ? true : false}
          success={crimeReport.crimeDateStatus === "success" ? true : false}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Time*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="crimeTime"
          type="time"
          value={crimeReport.crimeTime}
          onChange={handleCrimeReportDataChange}
          error={crimeReport.crimeTimeStatus === "error" ? true : false}
          success={crimeReport.crimeTimeStatus === "success" ? true : false}
        />
      </Grid>
      {/* <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Station*
          </MaterialTypography>
        </MaterialBox>
         <MaterialSelect
          name="station"
          value={crimeReport.station}
          customStyles={{ optionPadding: 20 }}
            onChange={handleCrimeReportDataChange}
          options={station?.map((value) => {
            return {
              value: value.value,
              label: value.label,
            };
          })}
          error={crimeReport.stationStatus === "error" ? true : false}
          success={crimeReport.stationStatus === "success" ? true : false}
          isClearable
        />
        {crimeReport.stationStatus === "error" && (
          <FormControl error>
            <FormHelperText>Station should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid> */}
      {/* //new added field  */}
 
     
      <Grid size={{ xs: 12, sm: 6}}>
       
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            City*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="city"
          value={crimeReport.city}
          // defaultValue={{ label: "Mr." }}
          customStyles={{ optionPadding: 20 }}
          onChange={handleCrimeReportDataChange}
          isSearchable={false}
          // options={cityselect?.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          options={cityselect}
 
          error={crimeReport.cityStatus === "error" ? true : false}
          success={crimeReport.cityStatus === "success" ? true : false}
          isClearable
        />
         {crimeReport.cityStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )}
      </Grid>
   
 
      <Grid size={{ xs: 12, sm: 6}}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            District*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="District"
          value={crimeReport.District}
          // defaultValue={{ label: "Mr." }}
          customStyles={{ optionPadding: 20 }}
          onChange={handleCrimeReportDataChange}
          isSearchable={false}
          // options={resources?.crimeType.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          options={districtselect}
 
          error={crimeReport.DistrictStatus === "error" ? true : false}
          success={crimeReport.DistrictStatus === "success" ? true : false}
          isClearable
        />
         {crimeReport.DistrictStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )}
      </Grid>
     
      <Grid size={{ xs: 12, sm: 6}}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Range*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="range"
          value={crimeReport.range}
          // defaultValue={{ label: "Mr." }}
          customStyles={{ optionPadding: 20 }}
          onChange={handleCrimeReportDataChange}
          isSearchable={false}
          // options={resources?.crimeType.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          options={rangeselect}
 
          error={crimeReport.rangeStatus === "error" ? true : false}
          success={crimeReport.rangeStatus === "success" ? true : false}
          isClearable
        />
         {crimeReport.rangeStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )}
      </Grid>
         
     
      <Grid size={{ xs: 12, sm: 6}}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Station*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="selectedvaluestation"
          value={crimeReport.selectedvaluestation}
          // defaultValue={{ label: "Mr." }}
          customStyles={{ optionPadding: 20 }}
          onChange={handleCrimeReportDataChange}
          isSearchable={false}
          // options={resources?.crimeType.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          options={stationselect}
 
          error={crimeReport.selectedvaluestationStatus === "error" ? true : false}
          success={crimeReport.selectedvaluestationStatus === "success" ? true : false}
          isClearable
        />
         {crimeReport.selectedvaluestationStatus === "error" && (
          <FormControl error>
            <FormHelperText>Please Select CCTV type</FormHelperText>
          </FormControl>
        )}
      </Grid>
   
     
     
      {/* //new added field  */}
      
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Investigated by*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="investigated"
          placeholder="eg. Raj"
          value={crimeReport.investigated}
          onChange={handleCrimeReportDataChange}
          error={crimeReport.investigatedStatus === "error" ? true : false}
          success={crimeReport.investigatedStatus === "success" ? true : false}
        />
        {crimeReport.investigatedStatus === "error" && (
          <FormControl error>
            <FormHelperText>Station should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Station Jurisdiction*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="jurisdiction"
          placeholder="eg. Selaiyur AC"
          value={crimeReport.jurisdiction}
          onChange={handleCrimeReportDataChange}
          error={crimeReport.jurisdictionStatus === "error" ? true : false}
          success={crimeReport.jurisdictionStatus === "success" ? true : false}
        />
        {crimeReport.jurisdictionStatus === "error" && (
          <FormControl error>
            <FormHelperText>jurisdiction should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Red flag Zone*
          </MaterialTypography>
        </MaterialBox>
        <MaterialBox ml={1}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={crimeReport.redZone}
              onChange={handleRedZoneChange}
            >
              <FormControlLabel
                value={"Yes"}
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel value={"No"} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </MaterialBox>
      </Grid>
    </Grid>
  );
};

export default CrimeForm;
