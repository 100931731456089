// CrimeTypeDialog.js
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialInput from "../../../../components/MaterialInput";
import { Button, Chip, FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import ImageUpload from "./ImageUpload";
import MaterialButton from "../../../../components/MaterialButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ImageList from "./ImageList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function CrimeTypeDialog({
  open, result,
  handleClose,
  addCrimeType,
  setAddCrimeType,
  setCrimeIcon,
  handleSubmit, setSelectedIndex, selectedIndex
}) {

  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');
  const [chips1, setChips1] = useState([]);
  const [chips2, setChips2] = useState([]);

  useEffect(() => {
    if (open) {
      setChips1([])
      setChips2([])
    }
  }, [open]);

  // Handle change in first input field
  const handleInputChange1 = (event) => {
    setInputValue1(event.target.value);
  };

  // Handle change in second input field
  const handleInputChange2 = (event) => {
    setInputValue2(event.target.value);
  };

  const handleAddChip1 = () => {
    if (inputValue1 && !chips1.includes(inputValue1)) {
      setChips1((prevChips) => [...prevChips, inputValue1]);
      setInputValue1(''); // Clear the first input field
    }
  };

  // Handle add chip for the second input field
  const handleAddChip2 = () => {
    if (inputValue2 && !chips2.includes(inputValue2)) {
      setChips2((prevChips) => [...prevChips, inputValue2]);
      setInputValue2(''); // Clear the second input field
    }
  };

  // Handle chip removal for the first field
  const handleDeleteChip1 = (chipToDelete) => () => {
    setChips1((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  // Handle chip removal for the second field
  const handleDeleteChip2 = (chipToDelete) => () => {
    setChips2((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleCrimeDataChange = (event, selectEvent) => {
    const changeAllowed = true;
    let userState = { ...addCrimeType };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "crimeType") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }
      userState[selectEvent.name] = event;
    } else {
      switch (event.target.name) {
        case "crimeType":
          {
            const alphaNLengthRegex = new RegExp("^([a-zA-Z ]{1,50})$");
            if (alphaNLengthRegex.test(event.target.value?.trim())) {
              userState[event.target.name + "Status"] = "success";
            } else {
              userState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
          case "head":
            {
              const alphaNLengthRegex = new RegExp("^([a-zA-Z ]{1,50})$");
              if (alphaNLengthRegex.test(event.target.value?.trim())) {
                userState[event.target.name + "Status"] = "success";
              } else {
                userState[event.target.name + "Status"] =
                  event.target.value === "" ? "unchecked" : "error";
              }
            }
            break;
      }
      if (changeAllowed) {
        userState[event.target.name] = event.target.value;
      }
    }
    setAddCrimeType(userState);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Crime Type Upload</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container>
            <Grid size={{ xs: 12, sm: 12 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography component="label" variant="caption" fontWeight="bold">
                   Type*
                </MaterialTypography>
              </MaterialBox>
              <MaterialInput
                name="crimeType"
                placeholder="eg. Law and Order"
                value={addCrimeType.crimeType}
                onChange={(e) => handleCrimeDataChange(e)}
                error={addCrimeType.crimeTypeStatus === "error"}
                success={addCrimeType.crimeTypeStatus === "success"}
              />
              {addCrimeType.crimeTypeStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Crime type should only contain alphabets</FormHelperText>
                </FormControl>
              )}
            </Grid>

            
            <Grid sx={{mt:2}} size={{ xs: 12, sm: 12 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography component="label" variant="caption" fontWeight="bold">
                  Head*
                </MaterialTypography>
              </MaterialBox>
              <MaterialInput
                name="head"
                placeholder="eg. Human Body"
                value={addCrimeType.head}
                onChange={(e) => handleCrimeDataChange(e)}
                error={addCrimeType.headStatus === "error"}
                success={addCrimeType.headStatus === "success"}
              />
              {addCrimeType.headStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Head should only contain alphabets</FormHelperText>
                </FormControl>
              )}
            </Grid>
           
              <Grid size={{ xs: 12, sm: 12 }}>
              <Stack direction="row" spacing={1} style={{ marginTop: 20 }}>
              {chips1.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={handleDeleteChip1(chip)}
                  color="primary"
                />
              ))}
            </Stack>
            <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography component="label" variant="caption" fontWeight="bold">
                  Sub Head*
                </MaterialTypography>
                </MaterialBox>
              <div style={{ display: 'flex' }}>
                
                <MaterialInput
                  // name="crimeType"
                  placeholder="eg. Murder"
                  value={inputValue1}
                  onChange={handleInputChange1}
                  // error={addCrimeType.crimeTypeStatus === "error"}
                  // success={addCrimeType.crimeTypeStatus === "success"}

                />
              <MaterialButton
                variant="gradient"
                color="dark"
                onClick={handleAddChip1}
                style={{ marginLeft: 10 }}
              >
                Add
              </MaterialButton>
              </div>
              </Grid>
             
            <br></br>
            {/* Second chip field */}
           

              <Grid size={{ xs: 12, sm: 12 }}>
              <Stack direction="row" spacing={1} style={{ marginTop: 20 }}>
              {chips2.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={handleDeleteChip2(chip)}
                  color="secondary"
                />
              ))}
            </Stack>
            <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography component="label" variant="caption" fontWeight="bold">
                  Crime Status*
                </MaterialTypography>
                </MaterialBox>
              <div style={{ display: 'flex' }}>

                <MaterialInput
                  // name="crimeType"
                  placeholder="eg. Rep"
                  value={inputValue2}
                  onChange={handleInputChange2}
                  // error={addCrimeType.crimeTypeStatus === "error"}
                  // success={addCrimeType.crimeTypeStatus === "success"}
                />
                <MaterialButton
                variant="gradient"
                color="dark"
                onClick={handleAddChip2}
                style={{ marginLeft: 10 }}
              >
                Add
              </MaterialButton>
            </div>

              </Grid>


            <Grid size={{ xs: 12, sm: 12 }} mt={2}>
              <ImageList result={result} setCrimeIcon={setCrimeIcon} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MaterialButton variant="outlined" color="dark" onClick={handleClose}>
          Cancel
        </MaterialButton>
        <MaterialButton
          variant="gradient"
          color="dark"
          style={{ maxWidth: "100px", minWidth: "100px" }}
          onClick={()=>{handleSubmit(chips1,chips2)}}
        >
          Submit
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
}

export default CrimeTypeDialog;
