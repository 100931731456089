import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layoutcontainers/DashboardLayout";
import CrimeMap from "./CrimeMap";
import { postData, fetchData, BASE_URL } from "../../../../BaseUrl";
import DynamicFilters from "./DynamicFilter";

function CrimMapingView() {
  const [crimeData, setCrimeData] = useState([]);
  const [locationData, setLocationData] = useState({
    locationName: "",
    latitude: "",
    longitude: "",
  });

  // State for crime filters
  const [crimeFilters, setCrimeFilters] = useState([]);
  const [crimeType, setCrimeType] = useState([]);
  const [user, setUser] = useState()

  useEffect(() => {
    getCrimeData();
    getTypeData()
    getUserId();
  }, []);

  useEffect(() => {
    if(user !== ""){
      getCrimeData();
    }
    
  }, [user]);

  const getUserId = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found.");
        return;
      }

      const response = await fetch(`${BASE_URL}api/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("response", response);
      const result = await response.json();
      console.log("resultuserId", result.userId);
     
        if (result && result.userId) {
          setUser(result.userId);
        } else {
          // console.log("No userId found in the response.");
        }
    } catch (error) {
      console.error("Error fetching userId:", error);
    }
  };


  const getTypeData = async () => {
    try {
      const data = await fetchData(`api/crimeType`);
      setCrimeType(data);
    } catch (error) {
      console.error("Error fetching crime data", error);
    } 
  };

  const getCrimeData = async () => {
    try {
      const result = await fetchData(`api/crimeIncidents/${user}`);
      console.log("result", result.data)
    //   result.data.map(item => {
    //     item.logourl = "https://keelis.com/Cicons/icon-0.3.png";
    //  })
    setCrimeData(result.data); // Set the crime data to state
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const handleLocationChange = (newLocationData) => {
    setLocationData(newLocationData); // Update location state
  };

  const handleCrimeFilterChange = (filters) => {
    console.log("filters", filters)
    setCrimeFilters(filters.data); // Update crime filter state
  };

  return (
    <div>
      <DashboardLayout>
        <DynamicFilters
        getCrimeData={getCrimeData}
          locationData={locationData}
          onLocationChange={handleLocationChange}
          onCrimeFilterChange={handleCrimeFilterChange} // Pass callback for crime filter change
        />
        <CrimeMap
        crimeType={crimeType}
        getCrimeData={getCrimeData}
          crimeData={crimeData}
          locationData={locationData}
          crimeFilters={crimeFilters} // Pass the crime filters to CrimeMap
        />
      </DashboardLayout>
    </div>
  );
}

export default CrimMapingView;
