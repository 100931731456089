import React, { useState } from "react";
import MaterialButton from "../../../../components/MaterialButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import { BASE_URL } from "../../../../BaseUrl"; // Ensure this is correct
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FileUpload = ({getIncidentId}) => {
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log("selectedFile", selectedFile);
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (
        fileExtension === "xlsx" ||
        fileExtension === "xls" ||
        fileExtension === "csv"
      ) {
        setFile(selectedFile);
        setStatus(""); // Clear status when a valid file is selected
        handleClickOpen(); // Open the dialog to show the upload status
      } else {
        setFile(null);
        setStatus("Please upload an Excel (.xlsx or .xls) or CSV (.csv) file.");
        handleClickOpen(); // Open the dialog to show the error message
      }
    }
  };

  // Handle form submission (file upload)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setStatus("Please select a valid file first.");
      return;
    }

    const formData = new FormData();
    formData.append("crimeIncident", file); // Ensure this matches the field name expected by Multer

    try {
      setStatus("Uploading...");

      const result = await fetch(`${BASE_URL}api/upload`, {
        method: "POST",
        body: formData,
      });

      // console.log("Upload result:", result);

      if (result.ok) {
        const response = await result.json();
        setStatus("File uploaded successfully!");
        handleClose()
        getIncidentId()
        setOpenDialog(true)
        
      } else {
        const errorResponse = await result.json();
        setStatus(errorResponse.message || "Failed to upload the file.");
      }
    } catch (error) {
      console.error("Request failed:", error); // Log any network or request error
      setStatus(`Error: ${error.message}`);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click(); // Trigger the hidden file input click
  };

  
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogConfirm = () => {
    setOpenDialog(false); // Close the dialog after confirming
  };

  // Function to handle dialog cancellation (just close the dialog)
  const handleDialogCancel = () => {
    setOpenDialog(false); // Simply close the dialog without submitting
  };

  return (
    <div>
      <MaterialButton
        variant="gradient"
        color="dark"
        style={{ maxWidth: "180px", minWidth: "180px" }}
        onClick={handleButtonClick}
      >
        Bulk Upload +
      </MaterialButton>

      <input
        type="file"
        id="fileInput"
        accept=".xlsx, .xls, .csv"
        style={{ display: "none" }}
        onChange={handleFileChange} // Handle the file selection
      />
<ConfirmationDialog
        open={openDialog} // Controls whether the dialog is shown
        showCancel={true} // Show the cancel button
        showConfirm={true} // Show the confirm button
        title="Confirmation" // Title of the dialog
        message="Your excel upload successfully" // Message in the dialog
        onConfirm={handleDialogConfirm} // Function to call when "Confirm" is clicked
        onCancel={handleDialogCancel} // Function to call when "Cancel" is clicked
      />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Upload Excel file</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {file?.name}
              </MaterialTypography>
              <br />
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {status}
              </MaterialTypography>
            </MaterialBox>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton variant="outlined" color="dark" onClick={handleClose}>
            Cancel
          </MaterialButton>
          <MaterialButton
            variant="gradient"
            color="dark"
            style={{ maxWidth: "100px", minWidth: "100px" }}
            onClick={handleSubmit}
          >
            Upload
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileUpload;
