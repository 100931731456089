import React, { useState, useEffect } from "react";
import axios from "axios";
import UserDataForm from "./UserDataForm"; // Your form component
import DashboardLayout from "../../../../layoutcontainers/DashboardLayout";
import MaterialBox from "../../../../components/MaterialBox";
import { postData } from "../../../../BaseUrl";
import MaterialButton from "../../../../components/MaterialButton";
import UserDataGrid from "./UserDataGrid";
import bgImage from "../../../../assest/bg-image.png";

const UserMaster = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  

  return (
    <MaterialBox>
      <DashboardLayout>
        <div sx={{
            position: "relative",
          }}>
        <img
            src={bgImage}
            alt="background"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.2,
            }}
          />
        <MaterialButton
          variant="gradient"
          color="dark"
          onClick={handleClickOpen}
        >
          Add +
        </MaterialButton>
        <UserDataForm
          open={open}
          setOpen={setOpen}
        />
        <UserDataGrid/>
        </div>
      </DashboardLayout>
    </MaterialBox>
  );
};

export default UserMaster;
