import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
  DirectionsRenderer,
  Polygon,
  Circle,
  DrawingManager,
} from "@react-google-maps/api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Alert, IconButton, Snackbar } from "@mui/material";
import MaterialTypography from "../../../../components/MaterialTypography";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import { deleteData, fetchData } from "../../../../BaseUrl";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: 12.9068,
  lng: 80.1425,
};

const CrimeMap = ({
  crimeData,
  locationData,
  crimeFilters,
  getCrimeData,
  crimeType,
}) => {
  // console.log("crimeType", crimeType)
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [selectedCrime, setSelectedCrime] = useState(null);
  const [policeStations, setPoliceStations] = useState([]);
  const [distance, setDistance] = useState(null);
  const [crimeLocation, setCrimeLocation] = useState("");
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [mapMarker, setMapMarker] = useState(null);
  const [mapData, setMapData] = useState([]);
  const [colorLegend, setColorLegend] = useState([]);
  const infoWindowRef = useRef(null);
  const [isLegendOpen, setIsLegendOpen] = useState(true);
  const mapRef = useRef(null); // Reference to map instance
  const fullscreenButtonRef = useRef(null);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const crimes = [
    {
      icons: [
        "https://keelis.com/Cicons/icon-.2.png",
        "https://keelis.com/Cicons/icon-6.1.png",
        "https://keelis.com/Cicons/icon-6.3.png",
      ],
    },
    {
      icons: [
        "https://keelis.com/Cicons/icon-0.3.png",
        "https://keelis.com/Cicons/icon-2.1.png",
        "https://keelis.com/Cicons/icon-2%203.png",
      ],
    },
    {
      icons: [
        "https://keelis.com/Cicons/iCON-4.0.png",
        "https://keelis.com/Cicons/icon-4.3.png",
        "https://keelis.com/Cicons/icon-4.2.png",
      ],
    },
    {
      icons: [
        "https://keelis.com/Cicons/icon-5.1.png",
        "https://keelis.com/Cicons/icon-5.3.png",
        "https://keelis.com/Cicons/icon-5.2.png",
      ],
    },
    {
      icons: [
        "https://keelis.com/Cicons/iCON-1%205.png",
        "https://keelis.com/Cicons/icon-0.1.png",
        "https://keelis.com/Cicons/icon-1.3 1.png",
      ],
    },
    {
      icons: [
        "https://keelis.com/Cicons/icon-3%203.png",
        "https://keelis.com/Cicons/icon-3.2.png",
        "https://keelis.com/Cicons/icon-0.2.png",
      ],
    },
  ];

  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate("/crime-reporting-form", { state: { id: selectedCrime.id } });
  };

  const toggleLegend = () => {
    setIsLegendOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (crimeFilters && crimeFilters.length > 0) {
      // console.log("Crimefilter", crimeFilters)
      setMapData(crimeFilters);
      generateColorLegend(crimeFilters);
    } else {
      setMapData(crimeData);
      generateColorLegend(crimeData);
    }

    if (crimeFilters && crimeFilters.length > 0) {
      const fullscreenControl = mapRef.current
        ?.getDiv()
        .querySelector(".gm-fullscreen-control");
      if (fullscreenControl) {
        // Simulate a click on the fullscreen control button to open the map in full-screen
        fullscreenControl.click();
      }
    }
  }, [crimeData, crimeFilters]);

  const onLoad = useCallback(function callback(mapInstance) {
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  // const getColorForYear = (year) => {
  //   const baseHue = 200;
  //   const hueRange = 180;

  //   const normalizedYear = (year - 2015) / (2024 - 2015);

  //   const hue = (baseHue + normalizedYear * hueRange) % 360;

  //   const { r, g, b } = hslToRgb(hue, 0.8, 0.6);
  //   return rgbToHex(r, g, b);
  // };

  const getColorForYear = (year) => {
    const baseYear = 2024;
    const iconCount = 3; // The number of icons in the array

    // Calculate the index based on the year
    // const index = (year - baseYear) % iconCount;
    const index = (((year - baseYear) % iconCount) + iconCount) % iconCount;

    return index;
  };

  const hslToRgb = (h, s, l) => {
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;

    let r, g, b;
    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    return {
      r: Math.round((r + m) * 255),
      g: Math.round((g + m) * 255),
      b: Math.round((b + m) * 255),
    };
  };

  const rgbToHex = (r, g, b) => {
    return ((1 << 24) | (r << 16) | (g << 8) | b)
      .toString(16)
      .slice(1)
      .toUpperCase();
  };

  // const generateColorLegend = (data) => {
  //   const years = new Set(
  //     data.map((item) => new Date(item.date).getFullYear())
  //   );

  //   const legend = Array.from(years).map((year) => ({
  //     year,
  //     color: getColorForYear(year),
  //   }));

  //   setColorLegend(legend);
  // };
  const generateColorLegend = (data) => {
    console.log("data", data);

    const years = new Set(
      data.map((item) => new Date(item.date).getFullYear())
    );

    // Map years to their corresponding index
    const legend = Array.from(years).map((year) => ({
      year,
      index: getColorForYear(year), // Get the icon index for each year
    }));

    setColorLegend(legend); // Assuming this sets the color legend in your application
  };

  // const getIconForType = (iconType, color,logo) => {

  //   console.log("crimeType", crimeType)
  //   console.log("crimeda", crimeData)

  //   const crimeIcon = crimeType.find((icon) => icon.name === logo);

  //   if (crimeIcon && crimeIcon.logo_url && color) {
  //     const urlParts = crimeIcon.logo_url.split("/48/");

  //     if (urlParts.length >= 2) {
  //       const baseUrl = urlParts[0];

  //       const imageName = urlParts[1].split("/")[1];

  //       if (imageName) {
  //         const newIconUrl = `${baseUrl}/48/${color}/${imageName}`;
  //         return newIconUrl;
  //       } else {
  //         console.error(
  //           "Error: Unable to extract the image name from the URL."
  //         );
  //       }
  //     } else {
  //       console.error("Error: logo_url does not contain '/50/'");
  //     }
  //   } else {
  //     console.error("Error: Missing crime icon or color parameter.");
  //   }

  //   return `https://img.icons8.com/ios-filled/48/${color}/arrest.png`;
  // };

  const getIconForType = (iconType, color, logo) => {
    // Loop through each crime category
    console.log("logoaaa",iconType,color,logo)
    for (let crime of crimes) {
      // Check if the logo exists in the icons array of the category
      // console.log("Matching logo found in category:", crime.icons.includes("https://keelis.com/Cicons/icon-0.3.png"));
      if (crime.icons.includes(logo)) {
        // console.log("Matching logo found in category:", crime);

        // Return the entire icons array of the matched category
        return crime.icons[color];
      }
    }

    // If no matching logo is found, return the default icon array (one item as fallback)
    console.error("Error: Logo not found in any category.");
    return [`https://img.icons8.com/ios-filled/48/${color}/arrest.png`]; // Default icon array
  };

  const fetchNearbyPoliceStations = (location) => {
    if (!map || !window.google || !window.google.maps) {
      console.error("Google Maps not loaded");
      return;
    }

    const service = new window.google.maps.places.PlacesService(map);
    const request = {
      location: location,
      radius: 5000,
      type: ["police"],
    };

    service.nearbySearch(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const filteredPoliceStations = results.filter(
          (place) =>
            place.name.toLowerCase().includes("police station") ||
            place.types.includes("police_station")
        );
        setPoliceStations(filteredPoliceStations);
      } else {
        console.error("Failed to fetch nearby police stations:", status);
      }
    });
  };

  // const handleMarkerClick = (crimeLocation, crimeDetails) => {
  //   setSelectedCrime(crimeDetails);

  //   const geocoder = new window.google.maps.Geocoder();

  //   geocoder.geocode(
  //     { location: { lat: crimeLocation.lat, lng: crimeLocation.lng } },
  //     (results, status) => {
  //       if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
  //         setCrimeLocation(results[0].formatted_address);
  //       } else {
  //         setCrimeLocation("Address not found.");
  //       }
  //     }
  //   );

  //   // Check if the browser supports geolocation and prompt the user
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const currentLocation = new window.google.maps.LatLng(
  //           position.coords.latitude,
  //           position.coords.longitude
  //         );
  //         console.log("Current Location", position.coords.latitude, position.coords.longitude);

  //         // Set up the DirectionsService to get directions to the user's current location
  //         const directionsService = new window.google.maps.DirectionsService();

  //         directionsService.route(
  //           {
  //             origin: crimeLocation, // Starting point (crime location)
  //             destination: currentLocation, // Ending point (current location)
  //             travelMode: window.google.maps.TravelMode.DRIVING, // Use driving directions
  //           },
  //           (result, status) => {
  //             if (status === window.google.maps.DirectionsStatus.OK) {
  //               setDirections(result);
  //             } else {
  //               alert("Error fetching directions: " + status);
  //             }
  //           }
  //         );
  //       },
  //       (error) => {
  //         console.error("Geolocation error: ", error);
  //         switch (error.code) {
  //           case error.PERMISSION_DENIED:
  //             alert("Location permission denied. Please enable location access.");
  //             break;
  //           case error.POSITION_UNAVAILABLE:
  //             alert("Location information is unavailable. Please check your network or GPS.");
  //             break;
  //           case error.TIMEOUT:
  //             alert("Geolocation request timed out. Please try again.");
  //             break;
  //           default:
  //             alert("An unknown error occurred.");
  //         }
  //       },
  //       {
  //         // Add options to improve location accuracy
  //         enableHighAccuracy: true, // Try to get the most accurate location possible
  //         timeout: 100000, // Timeout after 10 seconds
  //         maximumAge: 0, // Don't use a cached location
  //       }
  //     );
  //   } else {
  //     alert("Geolocation is not supported by this browser.");
  //   }
  // };

  const handleMarkerClick = (crimeLocation, crimeDetails) => {
    setSelectedCrime(crimeDetails);

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { location: { lat: crimeLocation.lat, lng: crimeLocation.lng } },
      (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          setCrimeLocation(results[0].formatted_address);
        } else {
          setCrimeLocation("Address not found.");
        }
      }
    );

    // Check if the browser supports geolocation and prompt the user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = new window.google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          console.log(
            "Current Location",
            position.coords.latitude,
            position.coords.longitude
          );

          // Set up the DirectionsService to get directions to the user's current location
          const directionsService = new window.google.maps.DirectionsService();

          directionsService.route(
            {
              origin: currentLocation, // Starting point (user's current location)
              destination: crimeLocation, // Ending point (crime location)
              travelMode: window.google.maps.TravelMode.DRIVING, // Use driving directions
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
                // Extract the distance from the result object
                const route = result.routes[0];
                const distanceText = route.legs[0].distance.text; // Get the readable distance (e.g., "3.5 km")
                console.log("distanceText", distanceText);
                setDistance(distanceText); // Set the distance state
                handleClick();
              } else {
                alert("Error fetching directions: " + status);
              }
            }
          );
        },
        (error) => {
          console.error("Geolocation error: ", error);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert(
                "Location permission denied. Please enable location access."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              alert(
                "Location information is unavailable. Please check your network or GPS."
              );
              break;
            case error.TIMEOUT:
              alert("Geolocation request timed out. Please try again.");
              break;
            default:
              alert("An unknown error occurred.");
          }
        },
        {
          enableHighAccuracy: true, // Try to get the most accurate location possible
          timeout: 100000, // Timeout after 10 seconds
          maximumAge: 0, // Don't use a cached location
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const setLocationCenter = useCallback(async () => {
    if (locationData.latitude && locationData.longitude) {
      setMapCenter({
        lat: parseFloat(locationData.latitude),
        lng: parseFloat(locationData.longitude),
      });
      setMapMarker({
        lat: parseFloat(locationData.latitude),
        lng: parseFloat(locationData.longitude),
      });
    } else if (locationData.locationName) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode(
        { address: locationData.locationName },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            const newCenter = results[0].geometry.location;
            setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
            setMapMarker({ lat: newCenter.lat(), lng: newCenter.lng() });
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  }, [locationData]);

  useEffect(() => {
    if (map) {
      fetchNearbyPoliceStations(mapCenter);
    }
  }, [map, mapCenter]);

  useEffect(() => {
    setLocationCenter();
  }, [locationData, setLocationCenter]);

  const mapBounds = {
    north: 13.15,
    south: 12.6,
    east: 80.35,
    west: 79.9,
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleDialogConfirm = async () => {
    setOpenDialog(false);
    try {
      const result = await deleteData(`api/crime/${deleteId}`);
    } catch (error) {
      console.error(`Error deleting item with ID ${deleteId}:`, error);
    }
    getCrimeData();
    setSelectedCrime(null);
    setDirections(null);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };

  const handleDialogCancel = () => {
    setOpenDialog(false);
  };

  const customMapStyle = [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [
        {
          visibility: "off", // Hide all labels
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "on", // Show road labels (street names)
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [
        {
          visibility: "on", // Show administrative area labels (city names)
        },
      ],
    },
  ];

  const [polygon, setPolygon] = useState(null);

  // Function to handle the overlay complete event (when a polygon is drawn)
  const handleOverlayComplete = useCallback((e) => {
    // Get the path of the drawn polygon
    const path = e.overlay.getPath();
    const coordinates = [];

    path.forEach((latLng) => {
      coordinates.push({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
    });

    // Set the coordinates into the state
    setPolygon(coordinates);

    // Log the coordinates to the console
    // console.log('Polygon Coordinates:', coordinates);

    // Send the coordinates to the API
    sendDataToAPI(coordinates);
  }, []);

  const sendDataToAPI = async (coordinates) => {
    const apiUrl = "YOUR_API_URL"; // Replace with your API endpoint

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ boundary: coordinates }),
      });
      const result = await response.json();
      console.log("Boundary saved successfully:", result);
    } catch (error) {
      console.error("Error saving boundary data:", error);
    }
  };

  return (
    <>
      {/* {console.log("colorLegend",colorLegend)} */}
      <ConfirmationDialog
        open={openDialog}
        showCancel={true}
        showConfirm={true}
        title="Confirm Deletion"
        message="Are you sure you want to detele this crime detail?"
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogCancel}
      />
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places", "geometry", "directions", "drawing"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={15}
          onLoad={(map) => {
            mapRef.current = map;
          }}
          onUnmount={onUnmount}
          options={{
            styles: customMapStyle,
            restriction: {
              latLngBounds: mapBounds,
              strictBounds: false,
            },
            fullscreenControl: true,
          }}
        >
          {/* <DrawingManager
          onPolygonComplete={handleOverlayComplete}
          drawingMode="polygon"
          options={{
            polygonOptions: {
              fillColor: '#ffff00',
              fillOpacity: 0.5,
              strokeColor: '#0000FF',
              strokeWeight: 2,
            },
          }}
        />
        {polygon && <Polygon paths={polygon} options={{ strokeColor: "#FF0000", strokeWeight: 2 }} />} */}

          {mapData?.map((incident) => {
            const isRedFlag = incident.red_flag_zone === "Yes";
            const year = new Date(incident.date).getFullYear();
            console.log("year", year)
            const color = getColorForYear(year);
            console.log("color", color)

            return (
              <>
                {isRedFlag && (
                  <Circle
                    center={{ lat: incident.latitude, lng: incident.longitude }}
                    radius={50}
                    options={{
                      fillColor: "#FF0000",
                      fillOpacity: 0.3,
                      strokeColor: "#FF0000",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    }}
                  />
                )}

                <Marker
                  key={incident.incident_id}
                  position={{ lat: incident.latitude, lng: incident.longitude }}
                  title={`Crime Type: ${incident.crimeType}`}
                  icon={{
                    url: getIconForType(
                      incident.crimeType,
                      color,
                      incident.logo_url
                    ),
                    scaledSize: { width: 30, height: 30 },
                  }}
                  onClick={() =>
                    handleMarkerClick(
                      { lat: incident.latitude, lng: incident.longitude },
                      incident
                    )
                  }
                />
              </>
            );
          })}

          {mapMarker && (
            <Marker
              position={mapMarker}
              title={locationData.locationName || "Custom Location"}
              icon={{
                url: "https://img.icons8.com/ios-filled/50/000000/marker.png",
                scaledSize: { width: 30, height: 30 },
              }}
            />
          )}

          {policeStations.map((station) => (
            <Marker
              key={station.place_id}
              position={station.geometry.location}
              title={station.name}
              icon={{
                url: "https://img.icons8.com/ios-filled/50/F01212/police-station.png",
                scaledSize: { width: 25, height: 25 },
              }}
            />
          ))}

          {directions && <DirectionsRenderer directions={directions} />}
          {distance && (
            <div className="distance-info">Distance to crime: {distance}</div>
          )}

          {selectedCrime && (
            <InfoWindow
              position={{
                lat: selectedCrime.latitude,
                lng: selectedCrime.longitude,
              }}
              onCloseClick={() => setSelectedCrime(null)}
              options={{
                pixelOffset: new window.google.maps.Size(0, -30),
              }}
              ref={infoWindowRef}
            >
              <div style={{ width: "300px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <MaterialTypography>Crime Details</MaterialTypography>
                  <div style={{ display: "flex" }}>
                    <IconButton sx={{ mt: "-2" }} onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      sx={{ mt: "-2" }}
                      onClick={() => {
                        handleDeleteClick(selectedCrime.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>

                <p>
                  <strong>Incident ID:</strong> {selectedCrime.incident_id}
                </p>
                <p>
                  <strong>Location:</strong> {crimeLocation}
                </p>
                <p>
                  <strong>Type:</strong> {selectedCrime.type}
                </p>
                <p>
                  <strong>Head:</strong> {selectedCrime.crimeType}
                </p>
                <p>
                  <strong>subHead:</strong> {selectedCrime.subHead}
                </p>
                <p>
                  <strong>crimeStatus:</strong> {selectedCrime.crimeStatus}
                </p>
                <p>
                  <strong>Date of occurrence:</strong>{" "}
                  {new Date(selectedCrime.date).toLocaleString()}
                </p>
                <p>
                  <strong>Crime Description:</strong>{" "}
                  {selectedCrime.reportedCrimeDetails ||
                    "No Crime description available."}
                </p>
                <p>
                  <strong>Station:</strong> {selectedCrime.station_location}
                </p>
                <p>
                  <strong>Station Range:</strong> {selectedCrime.range}
                </p>
                <p>
                  <strong>Station District:</strong> {selectedCrime.district}
                </p>
                <p>
                  <strong>Station City:</strong> {selectedCrime.city}
                </p>
                <p>
                  <strong>Investigated By:</strong>{" "}
                  {selectedCrime.investigated_by}
                </p>
                <p>
                  <strong>Station Jurisdiction:</strong>{" "}
                  {selectedCrime.station_jurisdiction}
                </p>
                <p>
                  <strong>Red Flag Zone:</strong> {selectedCrime.red_flag_zone}
                </p>
              </div>
            </InfoWindow>
          )}

          {isLegendOpen ? (
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "8px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                zIndex: 10,
                maxHeight: "200px",
                overflowY: "auto",
                width: "250px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Crime Year Color Legend
                </span>
                <IconButton onClick={toggleLegend}>
                  <CloseIcon />
                </IconButton>
              </div>
              {colorLegend.map((item) => (
                <div
                  key={item.year}
                  style={{ display: "flex", marginBottom: "10px" }}
                >
                  <span
                    style={{
                      // backgroundColor: `#${item.color}`,
                      backgroundColor:
                        item.index == 0
                          ? `#147f00`
                          : item.index == 1
                          ? `#c0c131`
                          : item.index == 2
                          ? "#9d53a0"
                          : "#147f00",

                      width: "10px",
                      height: "10px",
                      marginRight: "10px",
                    }}
                  ></span>
                  <span style={{ fontSize: "14px" }}>{item.year}</span>
                </div>
              ))}
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <span
                  style={{
                    backgroundColor: "rgba(255, 0, 0, 0.3)",
                    border: "2px solid rgba(255, 0, 0)",
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                ></span>
                <span style={{ fontSize: "14px" }}>Red zone</span>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                backgroundColor: "#fff",
                padding: "10px",
                borderRadius: "50%",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                zIndex: 10,
              }}
            >
              <IconButton onClick={toggleLegend}>
                <LaunchIcon />
              </IconButton>
            </div>
          )}
          <Snackbar
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top", // Position from top
              horizontal: "center", // Position from center
            }}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {distance} CCTV Found in particular radius
            </Alert>
          </Snackbar>
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default CrimeMap;
