import React, { useEffect, useState, useRef } from "react";
// import { fetchData } from "../../../../BaseUrl";
import bgImage from "../../../../assest/bg-image.png";
import { Grid } from "@mui/material";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialSelect from "../../../../components/MasterSelect";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialButton from "../../../../components/MaterialButton";
import {
  BASE_URL,
  fetchData,
  Getallstation,
  GetALLCity,
  Getdistrictofcity,
  Getrangeofdistrict,
  GetstationRange,
  GetALLtype,
  crimeFilterbasedtype,
} from "../../../../BaseUrl";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import MaterialInput from "../../../../components/MaterialInput";

const AbstractTable = () => {
  const tableStyles = {
    borderCollapse: "collapse",
    width: "100%",
    margin: "20px 0",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  };

  const thTdStyles = {
    border: "1px solid black",
    padding: "8px",
    fontSize: "14px",
  };

  const headerStyles = {
    fontWeight: "bold",
    backgroundColor: "#f2f2f2",
  };

  const captureRef = useRef();

  const [abstractData, setAbstractData] = useState([]);

  const [stationlist, setstationlist] = useState([]);

  const [userReport, setUserReport] = useState({
    userId: "",
    userName: "",
    reportCrime: "",
    crimeType: "",
    type: "",
    subHead: "",
    crimestatus: "",
    Head: "",
    Status: "",
    Station: "",
    userIdStatus: "unchecked",
    userNameStatus: "uncheked",
    reportcrimestatus: "uncheked",
    crimeTypeStatus: "uncheked",
    typeStatus: "unchecked",
    subHeadStatus: "unchecked",
    crimestatusStatus: "unchecked",
  });
  const [stationselect, setstationselect] = useState([]);
  const [rangeselect, setrangeselect] = useState([]);
  const [districtselect, setdistrictselect] = useState([]);
  const [cityselect, setcityselect] = useState([]);
  const [typeoption, settypeoption] = useState([]);

  const [crimeReport, setCrimeReport] = useState({
    city: "",
    District: "",
    range: "",
    selectedvaluestation: "",
  });

  const [crimeFromDate, setCrimeFromDate] = useState("");
  const [crimeToDate, setCrimeToDate] = useState("");
  const [error, setError] = useState("");

  const handleCrimeFromDateChange = (event) => {
    setCrimeFromDate(event.target.value);
  };

  const handleCrimeToDateChange = (event) => {
    setCrimeToDate(event.target.value);
    if (new Date(crimeToDate) < new Date(crimeFromDate)) {
      setError("To Date cannot be earlier than From Date");
      return;
    }

    setError("");
  };

  const captureAndGeneratePdf = () => {
    // Capture the div using html2canvas
    html2canvas(captureRef.current).then((canvas) => {
      // Convert the canvas to image data (base64 string)
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance with 'landscape' orientation
      const pdf = new jsPDF("landscape"); // 'landscape' sets the orientation to landscape

      // Add the image to the PDF
      pdf.addImage(imgData, "PNG", 10, 10, 270, 150); // Adjust positioning and size

      // Save the generated PDF
      pdf.save("captured-div-landscape.pdf");
    });
  };

  useEffect(() => {
    getallcity();
    getalltype();
  }, []);

  useEffect(() => {
    getalldistrictbasedoncity(userReport.type);
  }, [userReport.type]);
  useEffect(() => {
    getallRangebasedonDistrict(userReport.Head);
  }, [userReport.Head]);
  useEffect(() => {
    getallstationbasedonRange(userReport.subHead);
  }, [userReport.subHead]);

  // http://localhost:3000/api/CrimefilterStatics?type=Crime&subHead=HB by Day

  console.log("userReport.subHead", userReport.subHead);
  const getallcity = async () => {
    // try {
    //   const result = await GetALLCity("api/stations");
    //   //  console.log("result",result)
    //    if(result.stations.length>0)
    //   {
    //     setcityselect(result.stations)
    //   }
    // } catch (error) {
    //   console.error("Error fetching incident ID:", error);
    // }
    try {
      const result = await Getallstation("api/station");

      let arr = [];
      arr.push(result.stations);
      setstationlist(arr);
    } catch (err) {
      console.error("Error posting data:", err);
    }
  };

  const getalltype = async () => {
    try {
      const result = await GetALLtype("api/TypeCrime");
      console.log("resultaa", result);
      if (result.length > 0) {
        settypeoption(result);
        setUserReport({
          ...userReport,
          type: { label: result[0].label, value: result[0].value },
        });
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getalldistrictbasedoncity = async (value) => {
    try {
      const result = await Getdistrictofcity(
        `api/crimeTypes/name?filterValue=${value.label}`
      );

      if (result.length > 0) {
        setdistrictselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallRangebasedonDistrict = async (value) => {
    console.log("value", value);
    try {
      const result = await Getrangeofdistrict(
        `api/crimeTypes/head?filterValue=${value.label}`
      );
      console.log("resulr", result);
      if (result.length > 0) {
        setrangeselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallstationbasedonRange = async (value) => {
    try {
      const result = await GetstationRange(
        `api/crimeTypes/subhead?filterValue=${value.label}`
      );

      if (result.length > 0) {
        setstationselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  // const fetchstationdetails = async () => {
  //   try {
  //     const result = await Getallstation("api/station");

  //     let arr=[]
  //     arr.push(result.stations)
  //     setstationlist(arr);

  //   } catch (err) {
  //     console.error("Error posting data:", err);
  //   }
  // };

  const submitFilter = (
    type,
    crimeType,
    subHead,
    crimeStatus,
    station_location
  ) => {
    // console.log("type",type,crimeType,subHead,crimeStatus,station_location)
    let queryParts = [];

    // Check for the existing parameters and add them to the query string

    if (type) queryParts.push(`type=${type}`);

    if (station_location)
      queryParts.push(`station_location=${station_location}`);

    // Adding new parameters based on the new requirements
    if (subHead) queryParts.push(`subHead=${subHead}`);
    if (crimeType) queryParts.push(`crimeType=${crimeType}`);
    if (crimeStatus) queryParts.push(`crimestatus=${crimeStatus}`);
    // if (crimeFromDate) queryParts.push(`crimeFromDate=${crimeFromDate}`);
    // if (crimeToDate) queryParts.push(`crimeToDate=${crimeToDate}`);

    let queryString = queryParts.length > 0 ? `?${queryParts.join("&")}` : "";

    clickgetdatafilter(queryString);
  };

  // http://localhost:3000/api/CrimefilterStatics?type=Crime&subHead=HB by Day

  const clickgetdatafilter = async (querystring) => {
    try {
      const result = await crimeFilterbasedtype(
        `${BASE_URL}api/CrimefilterStatics?${querystring}`
      );
      // console.log("resultfilter from new",result.data)
      console.log("Crime data fetched filter", result);
      setAbstractData(result);
    } catch (error) {
      console.error("Error fetching camera data:", error);
    }
  };

  const handleUserReportDataChange = (event, selectEvent) => {
    const changeAllowed = true;
    let userState = { ...userReport };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "type") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "Head") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "subhead") {
        console.log("event", event, selectEvent.name);
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "Status") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "Station") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }

      userState[selectEvent.name] = event;
    }
    // console.log("userState",userState)
    setUserReport(userState);
  };

  const handlesubhead = (event, selectEvent) => {
    if (selectEvent.name === "subhead") {
      setUserReport({
        ...userReport,
        subHead: event,
      });
    }
  };

  const handlecrimestatus = (event, selectEvent) => {
    if (selectEvent.name === "Status") {
      setUserReport({
        ...userReport,
        crimestatus: event,
      });
    }
  };

  useEffect(() => {
    getAbstractData();
  }, []);

  const getAbstractData = async () => {
    const result = await fetchData("api/crimeStatistics");
    console.log("resultddd", result);
    setAbstractData(result);
    console.log("result11111", result);
  };

  const type = [
    {
      label: "Law & Order",
      value: "Law & Order",
    },
    {
      label: "Crime",
      value: "Crime",
    },
  ];

  // Updated dummy data including totals directly in the data
  // const abstractData = [
  //   {
  //     head: 'Murder',
  //     currentYear: {
  //       inThisMonth: { rep: 1 },
  //       lastMonth: { rep: 0 },
  //       upToMonth: { rep: 3, con: 3, acq: 3, pt: 0, ntf: 0, ui: 0, od: 0 },
  //       total: { rep: 4, con: 3, acq: 3, pt: 0, ntf: 0, ui: 0, od: 0 }, // Total for current year
  //     },
  //     lastYear: {
  //       inThisMonth: { rep: 1 },
  //       upToMonth: { rep: 3 },
  //       total: { rep: 4 }, // Total for last year
  //     },
  //   },
  //   {
  //     head: 'Attempt to Murder',
  //     currentYear: {
  //       inThisMonth: { rep: 1 },
  //       lastMonth: { rep: 0 },
  //       upToMonth: { rep: 4, con: 2, acq: 1, pt: 0, ntf: 1, ui: 0, od: 0 },
  //       total: { rep: 5, con: 2, acq: 1, pt: 0, ntf: 1, ui: 0, od: 0 },
  //     },
  //     lastYear: {
  //       inThisMonth: { rep: 2 },
  //       upToMonth: { rep: 5 },
  //       total: { rep: 7 },
  //     },
  //   },
  //   {
  //     head: 'Grievous Hurt',
  //     currentYear: {
  //       inThisMonth: { rep: 1 },
  //       lastMonth: { rep: 0 },
  //       upToMonth: { rep: 2, con: 2, acq: 1, pt: 1, ntf: 0, ui: 0, od: 0 },
  //       total: { rep: 3, con: 2, acq: 1, pt: 1, ntf: 0, ui: 0, od: 0 },
  //     },
  //     lastYear: {
  //       inThisMonth: { rep: 2 },
  //       upToMonth: { rep: 3 },
  //       total: { rep: 5 },
  //     },
  //   },
  //   {
  //     head: 'Simple Hurt',
  //     currentYear: {
  //       inThisMonth: { rep: 0 },
  //       lastMonth: { rep: 0 },
  //       upToMonth: { rep: 17, con: 13, acq: 1, pt: 1, ntf: 0, ui: 30, od: 30 },
  //       total: { rep: 18, con: 13, acq: 1, pt: 1, ntf: 0, ui: 30, od: 30 },
  //     },
  //     lastYear: {
  //       inThisMonth: { rep: 2 },
  //       upToMonth: { rep: 3 },
  //       total: { rep: 5 },
  //     },
  //   },
  //   {
  //     head: 'Rioting',
  //     currentYear: {
  //       inThisMonth: { rep: 0 },
  //       lastMonth: { rep: 0 },
  //       upToMonth: { rep: 5, con: 4, acq: 1, pt: 0, ntf: 0, ui: 0, od: 2 },
  //       total: { rep: 6, con: 4, acq: 1, pt: 0, ntf: 0, ui: 0, od: 2 },
  //     },
  //     lastYear: {
  //       inThisMonth: { rep: 2 },
  //       upToMonth: { rep: 3 },
  //       total: { rep: 5 },
  //     },
  //   },
  //   {
  //     head: 'Assault on Public Servant',
  //     currentYear: {
  //       inThisMonth: { rep: 1 },
  //       lastMonth: { rep: 0 },
  //       upToMonth: { rep: 2, con: 2, acq: 0, pt: 0, ntf: 0, ui: 2, od: 2 },
  //       total: { rep: 3, con: 2, acq: 0, pt: 0, ntf: 0, ui: 2, od: 2 },
  //     },
  //     lastYear: {
  //       inThisMonth: { rep: 2 },
  //       upToMonth: { rep: 3 },
  //       total: { rep: 5 },
  //     },
  //   },
  // ];

  const propertyData = abstractData.filter((row) => row.head === "Property");
  const humanBodyData = abstractData.filter((row) => row.head === "Human Body");

  const calculateTotal = (index) => {
    let total = 0;
    propertyData.forEach((row) => {
      switch (index) {
        case 0: // "In this Month" Rep (Current Year)
          total += parseInt(row.currentYear.inThisMonth.rep) || 0;
          break;
        case 1: // "Last Month" Rep (Current Year)
          total += parseInt(row.currentYear.lastMonth.rep) || 0;
          break;
        case 2: // "Up to this Month" Rep (Current Year)
          total += parseInt(row.currentYear.upToMonth.rep) || 0;
          break;
        case 3: // "Up to this Month" Con (Current Year)
          total += parseInt(row.currentYear.upToMonth.con) || 0;
          break;
        case 4: // "Up to this Month" Acq (Current Year)
          total += parseInt(row.currentYear.upToMonth.acq) || 0;
          break;
        case 5: // "Up to this Month" PT (Current Year)
          total += parseInt(row.currentYear.upToMonth.pt) || 0;
          break;
        case 6: // "Up to this Month" NTF (Current Year)
          total += parseInt(row.currentYear.upToMonth.ntf) || 0;
          break;
        case 7: // "Up to this Month" UI (Current Year)
          total += parseInt(row.currentYear.upToMonth.ui) || 0;
          break;
        case 8: // "Up to this Month" OD (Current Year)
          total += parseInt(row.currentYear.upToMonth.od) || 0;
          break;
        case 9: // "In this Month" Rep (Last Year)
          total += parseInt(row.lastYear.inThisMonth.rep) || 0;
          break;
        case 10: // "Up to Month" Rep (Last Year)
          total += parseInt(row.lastYear.upToMonth.rep) || 0;
          break;
        default:
          break;
      }
    });
    return total;
  };

  const calculateTotals = (index) => {
    let total = 0;
    humanBodyData.forEach((row) => {
      switch (index) {
        case 0: // "In this Month" Rep (Current Year)
          total += parseInt(row.currentYear.inThisMonth.rep) || 0;
          break;
        case 1: // "Last Month" Rep (Current Year)
          total += parseInt(row.currentYear.lastMonth.rep) || 0;
          break;
        case 2: // "Up to this Month" Rep (Current Year)
          total += parseInt(row.currentYear.upToMonth.rep) || 0;
          break;
        case 3: // "Up to this Month" Con (Current Year)
          total += parseInt(row.currentYear.upToMonth.con) || 0;
          break;
        case 4: // "Up to this Month" Acq (Current Year)
          total += parseInt(row.currentYear.upToMonth.acq) || 0;
          break;
        case 5: // "Up to this Month" PT (Current Year)
          total += parseInt(row.currentYear.upToMonth.pt) || 0;
          break;
        case 6: // "Up to this Month" NTF (Current Year)
          total += parseInt(row.currentYear.upToMonth.ntf) || 0;
          break;
        case 7: // "Up to this Month" UI (Current Year)
          total += parseInt(row.currentYear.upToMonth.ui) || 0;
          break;
        case 8: // "Up to this Month" OD (Current Year)
          total += parseInt(row.currentYear.upToMonth.od) || 0;
          break;
        case 9: // "In this Month" Rep (Last Year)
          total += parseInt(row.lastYear.inThisMonth.rep) || 0;
          break;
        case 10: // "Up to Month" Rep (Last Year)
          total += parseInt(row.lastYear.upToMonth.rep) || 0;
          break;
        default:
          break;
      }
    });
    return total;
  };

  const handleReset = () => {
    setUserReport({
      userId: "",
      userName: "",
      reportCrime: "",
      crimeType: "",
      type: {
        label: "Crime",
        value: "Crime",
      },
      subHead: "",
      crimestatus: "",
      Head: "",
      Status: "",
      Station: "",
      userIdStatus: "unchecked",
      userNameStatus: "uncheked",
      reportcrimestatus: "uncheked",
      crimeTypeStatus: "uncheked",
      typeStatus: "unchecked",
      subHeadStatus: "unchecked",
      crimestatusStatus: "unchecked",
    });
    getAbstractData();
  };

  return (
    // <div style={{ padding: '20px', position: "relative", }}>
    //   <img
    //         src={bgImage}
    //         alt="background"
    //         style={{
    //           position: "absolute",
    //           top: "50%",
    //           left: "50%",
    //           transform: "translate(-50%, -50%)",
    //           width: "100%",
    //           height: "100%",
    //           objectFit: "cover",
    //           opacity: 0.2,
    //         }}
    //       />
    //   {/* <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>
    //     T12 SELAIYUR PS - L&O
    //   </h2>
    //   <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>
    //     Offences Affecting Human Body - October 2024
    //   </h3> */}
    //   <table style={tableStyles}>
    //     <thead>
    //       <tr>
    //         <th style={{ ...thTdStyles, ...headerStyles }} rowSpan="3">
    //           HEAD
    //         </th>
    //         <th style={{ ...thTdStyles, ...headerStyles }} colSpan="9">
    //           Current Year
    //         </th>
    //         <th style={{ ...thTdStyles, ...headerStyles }} colSpan="2">
    //           Last Year
    //         </th>
    //       </tr>
    //       <tr>
    //         <th style={thTdStyles}>In this Month</th>
    //         <th style={thTdStyles}>Last Month</th>
    //         <th style={thTdStyles} colSpan="7">
    //           Up to this Month
    //         </th>
    //         <th style={thTdStyles}>In this Month</th>
    //         <th style={thTdStyles}>Up to the Month</th>
    //       </tr>
    //       <tr>
    //         <th style={thTdStyles}>Rep</th>
    //         <th style={thTdStyles}>Rep</th>
    //         <th style={thTdStyles}>Rep</th>
    //         <th style={thTdStyles}>Con</th>
    //         <th style={thTdStyles}>Acq</th>
    //         <th style={thTdStyles}>PT</th>
    //         <th style={thTdStyles}>NTF</th>
    //         <th style={thTdStyles}>UI</th>
    //         <th style={thTdStyles}>OD</th>
    //         <th style={thTdStyles}>Rep</th>
    //         <th style={thTdStyles}>Rep</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {abstractData?.map((row, index) => (
    //         <tr key={index}>
    //           <td style={thTdStyles}><b>Head:</b>{row.head}  <br/> <b>Sub Head:</b> {row.subHead}</td>
    //           <td style={thTdStyles}>{row.currentYear.inThisMonth.rep}</td>
    //           <td style={thTdStyles}>{row.currentYear.lastMonth.rep}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.rep}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.con}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.acq}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.pt}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.ntf}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.ui}</td>
    //           <td style={thTdStyles}>{row.currentYear.upToMonth.od}</td>
    //           <td style={thTdStyles}>{row.lastYear.inThisMonth.rep}</td>
    //           <td style={thTdStyles}>{row.lastYear.upToMonth.rep}</td>
    //         </tr>
    //       ))}
    //       {/* Total Row */}
    //       <tr>
    //         <td style={{ ...thTdStyles, fontWeight: 'bold' }}>Total</td>
    //         <td colSpan="2" style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.rep),
    //             0
    //           )}
    //         </td>
    //         {/* <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => acc + parseInt(row.currentYear.total.rep),
    //             0
    //           )}
    //         </td> */}
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.rep),
    //             0
    //           )}
    //         </td>
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.con),
    //             0
    //           )}
    //         </td>
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.acq),
    //             0
    //           )}
    //         </td>
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.pt),
    //             0
    //           )}
    //         </td>
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.ntf),
    //             0
    //           )}
    //         </td>
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.ui),
    //             0
    //           )}
    //         </td>
    //         <td style={thTdStyles}>
    //           {abstractData.reduce(
    //             (acc, row) => parseInt(acc) + parseInt(row.currentYear.total.od),
    //             0
    //           )}
    //         </td>
    //         <td colSpan="2" style={thTdStyles}>
    //           {abstractData.reduce((acc, row) => parseInt(acc) + parseInt(row.lastYear.total.rep), 0)}
    //         </td>
    //         {/* <td style={thTdStyles}>
    //           {abstractData.reduce((acc, row) => parseInt(acc) + parseInt(row.lastYear.total.rep), 0)}
    //         </td> */}
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
    <div>
      {console.log("event", userReport.Head)}
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Type*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="type"
            value={userReport.type}
            onChange={handleUserReportDataChange}
            options={typeoption}
            // {type?.map((value) => {
            //   return {
            //     value: value.value,
            //     label: value.label,
            //   };
            // })}
            // isClearable
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Head*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="Head"
            value={userReport.Head}
            onChange={handleUserReportDataChange}
            options={districtselect}

            // {type?.map((value) => {
            //   return {
            //     value: value.value,
            //     label: value.label,
            //   };
            // })}
            // isClearable
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Subhead*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="subhead"
            value={userReport.subHead}
            onChange={handlesubhead}
            options={rangeselect}
            // {type?.map((value) => {
            //   return {
            //     value: value.value,
            //     label: value.label,
            //   };
            // })}

            // isClearable
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Status*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="Status"
            value={userReport.crimestatus}
            onChange={handlecrimestatus}
            options={stationselect}
            // {type?.map((value) => {
            //   return {
            //     value: value.value,
            //     label: value.label,
            //   };
            // })}

            // isClearable
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Station*
            </MaterialTypography>
          </MaterialBox>
          <MaterialSelect
            name="Station"
            value={userReport.Station}
            onChange={handleUserReportDataChange}
            options={stationlist[0]?.map((value) => {
              return {
                value: value.station_location,
                label: value.station_location,
              };
            })}
            // isClearable
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              From Date*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="crimeFromDate"
            type="date"
            value={crimeFromDate}
            onChange={handleCrimeFromDateChange}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              To Date*
            </MaterialTypography>
          </MaterialBox>
          <MaterialInput
            name="crimeToDate"
            type="date"
            value={crimeToDate}
            onChange={handleCrimeToDateChange}
          />
          {error && (
            <MaterialBox sx={{ color: "error.main", mt: 2 }}>
              <MaterialTypography variant="body2">{error}</MaterialTypography>
            </MaterialBox>
          )}
        </Grid> */}
        <Grid item xs={12} sm={2} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={() =>
                submitFilter(
                  userReport.type.label,
                  userReport.Head.label,
                  userReport.subHead.label,
                  userReport.crimestatus.label,
                  userReport.Station.label
                )
              }
            >
              Submit
            </MaterialButton>
          </MaterialBox>
        </Grid>

        <Grid item xs={12} sm={2} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={handleReset}
            >
              Reset
            </MaterialButton>
          </MaterialBox>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ mt: 3.6 }}>
          <MaterialBox lineHeight={0} display="inline-block">
            <MaterialButton
              variant="gradient"
              color="dark"
              onClick={captureAndGeneratePdf}
            >
              Export as Pdf
            </MaterialButton>
          </MaterialBox>
        </Grid>
      </Grid>
      <br />
      {/* <img
        src={bgImage}
        alt="background"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: 0.2,
        }}
      /> */}

      {/* Table for Offences Affecting Property */}
      {userReport.type.value !== "Law and Order" ? (
        <div ref={captureRef}>
          {" "}
          <h3 style={{ textAlign: "center" }}>
            Offences Affecting Property
          </h3>
          <h4 style={{
              textAlign: "center",
            }}>Year: 2024</h4>
          <table style={tableStyles}>
            <thead>
              <tr>
                <th style={{ ...thTdStyles, ...headerStyles }} rowSpan="3">
                  HEAD
                </th>
                <th style={{ ...thTdStyles, ...headerStyles }} colSpan="9">
                  Current Year
                </th>
                <th style={{ ...thTdStyles, ...headerStyles }} colSpan="2">
                  Last Year
                </th>
              </tr>
              <tr>
                <th style={thTdStyles}>In this Month</th>
                <th style={thTdStyles}>Last Month</th>
                <th style={thTdStyles} colSpan="7">
                  Up to this Month
                </th>
                <th style={thTdStyles}>In this Month</th>
                <th style={thTdStyles}>Up to the Month</th>
              </tr>
              <tr>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Con</th>
                <th style={thTdStyles}>Acq</th>
                <th style={thTdStyles}>PT</th>
                <th style={thTdStyles}>NTF</th>
                <th style={thTdStyles}>UI</th>
                <th style={thTdStyles}>OD</th>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Rep</th>
              </tr>
            </thead>
            <tbody>
              {propertyData.map((row, index) => (
                <tr key={index}>
                  <td style={thTdStyles}>{row.subHead}</td>
                  <td style={thTdStyles}>{row.currentYear.inThisMonth.rep}</td>
                  <td style={thTdStyles}>{row.currentYear.lastMonth.rep}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.rep}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.con}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.acq}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.pt}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.ntf}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.ui}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.od}</td>
                  <td style={thTdStyles}>{row.lastYear.inThisMonth.rep}</td>
                  <td style={thTdStyles}>{row.lastYear.upToMonth.rep}</td>
                </tr>
              ))}
              {/* Total Row */}
              <tr>
                <td style={{ ...thTdStyles, fontWeight: "bold" }}>Total</td>
                <td style={thTdStyles}>{calculateTotal(0)}</td>
                <td style={thTdStyles}>{calculateTotal(1)}</td>
                <td style={thTdStyles}>{calculateTotal(2)}</td>
                <td style={thTdStyles}>{calculateTotal(3)}</td>
                <td style={thTdStyles}>{calculateTotal(4)}</td>
                <td style={thTdStyles}>{calculateTotal(5)}</td>
                <td style={thTdStyles}>{calculateTotal(6)}</td>
                <td style={thTdStyles}>{calculateTotal(7)}</td>
                <td style={thTdStyles}>{calculateTotal(8)}</td>
                <td style={thTdStyles}>{calculateTotal(9)}</td>
                <td style={thTdStyles}>{calculateTotal(10)}</td>
              </tr>
            </tbody>
          </table>{" "}
        </div>
      ) : (
        <div ref={captureRef}>
          {" "}
          {/* Table for Offences Affecting Human Body */}
          <h3
            style={{
              textAlign: "center",
            }}
          >
            Offences Affecting Human Body
          </h3>
          <h4 style={{
              textAlign: "center",
            }}>Year: 2024</h4>
          <table style={tableStyles}>
            <thead>
              {/* Repeat the table header */}
              <tr>
                <th style={{ ...thTdStyles, ...headerStyles }} rowSpan="3">
                  HEAD
                </th>
                <th style={{ ...thTdStyles, ...headerStyles }} colSpan="9">
                  Current Year
                </th>
                <th style={{ ...thTdStyles, ...headerStyles }} colSpan="2">
                  Last Year
                </th>
              </tr>
              <tr>
                <th style={thTdStyles}>In this Month</th>
                <th style={thTdStyles}>Last Month</th>
                <th style={thTdStyles} colSpan="7">
                  Up to this Month
                </th>
                <th style={thTdStyles}>In this Month</th>
                <th style={thTdStyles}>Up to the Month</th>
              </tr>
              <tr>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Con</th>
                <th style={thTdStyles}>Acq</th>
                <th style={thTdStyles}>PT</th>
                <th style={thTdStyles}>NTF</th>
                <th style={thTdStyles}>UI</th>
                <th style={thTdStyles}>OD</th>
                <th style={thTdStyles}>Rep</th>
                <th style={thTdStyles}>Rep</th>
              </tr>
            </thead>
            <tbody>
              {humanBodyData.map((row, index) => (
                <tr key={index}>
                  <td style={thTdStyles}>{row.subHead}</td>
                  <td style={thTdStyles}>{row.currentYear.inThisMonth.rep}</td>
                  <td style={thTdStyles}>{row.currentYear.lastMonth.rep}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.rep}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.con}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.acq}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.pt}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.ntf}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.ui}</td>
                  <td style={thTdStyles}>{row.currentYear.upToMonth.od}</td>
                  <td style={thTdStyles}>{row.lastYear.inThisMonth.rep}</td>
                  <td style={thTdStyles}>{row.lastYear.upToMonth.rep}</td>
                </tr>
              ))}
              {/* Total Row */}
              <tr>
                <td style={{ ...thTdStyles, fontWeight: "bold" }}>Total</td>
                <td style={thTdStyles}>{calculateTotals(0)}</td>
                <td style={thTdStyles}>{calculateTotals(1)}</td>
                <td style={thTdStyles}>{calculateTotals(2)}</td>
                <td style={thTdStyles}>{calculateTotals(3)}</td>
                <td style={thTdStyles}>{calculateTotals(4)}</td>
                <td style={thTdStyles}>{calculateTotals(5)}</td>
                <td style={thTdStyles}>{calculateTotals(6)}</td>
                <td style={thTdStyles}>{calculateTotals(7)}</td>
                <td style={thTdStyles}>{calculateTotals(8)}</td>
                <td style={thTdStyles}>{calculateTotals(9)}</td>
                <td style={thTdStyles}>{calculateTotals(10)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AbstractTable;
