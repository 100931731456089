import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Icon,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import SigninLayout from "../../../layout/SigninLayout";
// import bgImage from "../../../assest/Logo.jpg";
import bgImage from "../../../assest/crimemappinglogobg.jpg";
import MaterialBox from "../../../components/MaterialBox";
import MaterialButton from "../../../components/MaterialButton";
import MaterialInput from "../../../components/MaterialInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ConfirmationDialog from "../../../components/Dialog/ConfirmationDialog";
import { postData } from '../../../BaseUrl';
import headlogo from '../../../assest/bg-image.png'

function Signin() {
  const navigate = useNavigate();

  const [pageState, setPageState] = useState({
    loading: false,
    error: false,
    message: "",
    state: "init",
  });
  const [signInData, setSignInData] = useState({
    userId: "",
    password: "",
    viewPassword: false,
    newPassword: "",
    viewNewPassword: false,
    confirmPassword: "",
    viewConfirmPassword: false,
    userIdStatus: "unchecked",
    passwordStatus: "unchecked",
    newPasswordStatus: "unchecked",
    confirmPasswordStatus: "unchecked",
  });
  const [user, setUser] = useState({
    name: "",
    userName: "",
    userId: "",
    picture: "",
    token: "",
    details: "",
  });
  const [stage, setStage] = useState("sign-in");

  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    showCancel: true,
    customSection: null,
    onConfirm: () => {},
    onCancel: () => {},
  });

  useEffect(() => {
    setPageState((prevState) => ({ ...prevState, state: "default" }));
  }, []);

  const handleSignIn = async () => {
     const value = {identifier:signInData.userId, password:signInData.password};
    setPageState({
      ...pageState,
      loading: true,
    });
    try {
        const result = await postData('api/login', value);
        localStorage.setItem('token', result.token);
        if(result?.token){
          navigate("/crime-reporting-form")
          discard()

        }
      } catch (err) {
        console.error('Error posting data:', err);
        discard()
       
      }
  };

  const discard=()=>
  {

    setSignInData({
      ...signInData,
      userId: "",
      password: "",
      viewPassword: false,
      newPassword: "",
      viewNewPassword: false,
      confirmPassword: "",
      viewConfirmPassword: false,
      userIdStatus: "unchecked",
      passwordStatus: "unchecked",
      newPasswordStatus: "unchecked",
      confirmPasswordStatus: "unchecked",
    })
  }

  const handleConfirmPassword = async () => {
   
  };

  const handleForgotPassword = async () => {
   
  };

  const handlePasswordVisibility = () => {
    setSignInData((prevState) => ({
      ...prevState,
      viewPassword: !prevState.viewPassword,
    }));
  };

  const handleSigninChange = (event) => {
    const userIdRegex = new RegExp("^[A-Za-z0-9]");
    const lowerCaseRegex = /^(?=.*[a-z])/;
    const upperCaseRegex = /^(?=.*[A-Z])/;
    const numberRegex = /^(?=.*[0-9])/;
    const specialCharacterRegex = /^(?=.*[@$!%*?&])/;
    const lengthRegex = /([a-zA-Z0-9@$!%*?&]{8,})$/;

    let signInState = { ...signInData };
    if (event.target.name === "userId") {
      signInState.userIdStatus = userIdRegex.test(event.target.value)
        ? "success"
        : "error";
    } else if (event.target.name === "password") {
      signInState.passwordStatus =
        lowerCaseRegex.test(event.target.value) ||
        upperCaseRegex.test(event.target.value) ||
        numberRegex.test(event.target.value) ||
        specialCharacterRegex.test(event.target.value)
          ? // lengthRegex.test(event.target.value)
            "success"
          : "error";
    } else if (event.target.name === "newPassword") {
      signInState.newPasswordStatus =
        lowerCaseRegex.test(event.target.value) &&
        upperCaseRegex.test(event.target.value) &&
        numberRegex.test(event.target.value) &&
        specialCharacterRegex.test(event.target.value) &&
        lengthRegex.test(event.target.value)
          ? "success"
          : "error";
      signInState.confirmPasswordStatus =
        signInState.confirmPassword === event.target.value
          ? "success"
          : "error";
    } else if (event.target.name === "confirmPassword") {
      signInState.confirmPasswordStatus =
        signInState.newPassword === event.target.value ? "success" : "error";
    }
    signInState[event.target.name] = event.target.value;
    setSignInData(signInState);
  };

  return (
    <SigninLayout
      description="Enter your userId and password to sign in"
      illustration={bgImage}
      title='Log In'
      headlogo={headlogo}
    >
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          showCancel={confirmationDialog.showCancel}
          showConfirm={confirmationDialog.showConfirm}
          customSection={confirmationDialog.customSection}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
        />
      )}
      {pageState.error && (
        <Dialog maxWidth="sm" open={pageState.error} fullWidth>
          <DialogTitle>
            <Typography variant="body1">{pageState.title}</Typography>
          </DialogTitle>
          <DialogContent variant="body2">{pageState.message}</DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="text"
              onClick={() =>
                setPageState((prevState) => ({ ...prevState, error: false }))
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {pageState.state === "default" && (
        <MaterialBox component="form" role="form">
          {stage === "sign-in" && (
            <>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <MaterialInput
                    fullWidth
                    placeholder="User Id"
                    name="userId"
                    variant="outlined"
                    value={signInData.userId}
                    onChange={handleSigninChange}
                    error={signInData.userIdStatus === "error"}
                    success={signInData.userIdStatus === "success"}
                    // disabled={pageState.loading === true ? true : false}
                  />
                  {signInData.userIdStatus === "error" && (
                    <MaterialBox
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <FormControl error>
                        <FormHelperText>
                          Please enter a valid userId
                        </FormHelperText>
                      </FormControl>
                    </MaterialBox>
                  )}
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <MaterialInput
                    fullWidth
                    placeholder="Password"
                    name="password"
                    type={signInData.viewPassword ? "text" : "password"}
                    variant="outlined"
                    value={signInData.password}
                    onChange={handleSigninChange}
                    error={signInData.passwordStatus === "error"}
                    success={signInData.passwordStatus === "success"}
                    // disabled={pageState.loading === true ? true : false}
                    icon={
                      signInData.viewPassword
                        ? {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityOffIcon
                                  onClick={handlePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                        : {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityIcon
                                  onClick={handlePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                    }
                  />
                  {signInData.passwordStatus === "error" && (
                    <FormControl error>
                      <FormHelperText>
                        Your password must be at least 8 characters long and
                        include a lowercase letter, uppercase letter, number,
                        and special character.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <MaterialBox display="flex" justifyContent="flex-end" my={2}>
                <MaterialButton
                  variant="text"
                  color="black"
                  onClick={() => {
                    handleForgotPassword();
                  }}
                  sx={{ padding: 0 }}
                  disabled={signInData.userIdStatus !== "success"}
                >
                  Forgot Password?
                </MaterialButton>
              </MaterialBox>
              <MaterialBox my={2}>
                <MaterialButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  size="large"
                  loadingPosition="start"
                  onClick={() => {
                    if (!pageState.loading) {
                      handleSignIn();
                    }
                  }}
                  disabled={
                    signInData.userIdStatus !== "success" ||
                    signInData.passwordStatus !== "success"
                  }
                //   loading={pageState.loading}
                >
                  Sign in
                </MaterialButton>
              </MaterialBox>
            </>
          )}

          {stage === "forgot-password" && (
            <>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <MaterialInput
                    fullWidth
                    placeholder="New password"
                    name="newPassword"
                    type={signInData.viewNewPassword ? "text" : "password"}
                    variant="outlined"
                    value={signInData.newPassword}
                    onChange={handleSigninChange}
                    error={signInData.newPasswordStatus === "error"}
                    success={signInData.newPasswordStatus === "success"}
                    disabled={pageState.loading === true ? true : false}
                    icon={
                      signInData.viewNewPassword
                        ? {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityOffIcon
                                  onClick={() => {
                                    setSignInData((prevState) => ({
                                      ...prevState,
                                      viewNewPassword:
                                        !prevState.viewNewPassword,
                                    }));
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                        : {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityIcon
                                  onClick={() => {
                                    setSignInData((prevState) => ({
                                      ...prevState,
                                      viewNewPassword:
                                        !prevState.viewNewPassword,
                                    }));
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                    }
                  />
                  {signInData.newPasswordStatus === "error" && (
                    <FormControl error>
                      <FormHelperText>
                        Your password must be at least 8 characters long and
                        include a lowercase letter, uppercase letter, number,
                        and special character.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <MaterialInput
                    fullWidth
                    placeholder="Confirm password"
                    name="confirmPassword"
                    type={signInData.viewConfirmPassword ? "text" : "password"}
                    variant="outlined"
                    value={signInData.confirmPassword}
                    onChange={handleSigninChange}
                    error={signInData.confirmPasswordStatus === "error"}
                    success={signInData.confirmPasswordStatus === "success"}
                    disabled={pageState.loading === true ? true : false}
                    icon={
                      signInData.viewConfirmPassword
                        ? {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityOffIcon
                                  onClick={() => {
                                    setSignInData((prevState) => ({
                                      ...prevState,
                                      viewConfirmPassword:
                                        !prevState.viewConfirmPassword,
                                    }));
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                        : {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityIcon
                                  onClick={() => {
                                    setSignInData((prevState) => ({
                                      ...prevState,
                                      viewConfirmPassword:
                                        !prevState.viewConfirmPassword,
                                    }));
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                    }
                  />
                  {signInData.confirmPasswordStatus === "error" && (
                    <FormControl error>
                      <FormHelperText>
                        New password and confirm password doesn't match. Please
                        check and try again.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
              <MaterialBox my={2}>
                <MaterialButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  size="medium"
                  loadingPosition="start"
                  onClick={() => {
                    if (!pageState.loading) {
                      handleConfirmPassword();
                    }
                  }}
                  disabled={
                    signInData.confirmPasswordStatus !== "success" ||
                    signInData.newPasswordStatus !== "success"
                  }
                  loading={pageState.loading}
                >
                  Confirm
                </MaterialButton>
              </MaterialBox>
            </>
          )}
        </MaterialBox>
      )}
    </SigninLayout>
  );
}

export default Signin;
