import React from "react";
import ReactD3Tree from "react-d3-tree";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import MaterialButton from "../../../../components/MaterialButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let treeData;

const StationFlowChart = ({ open, setOpen, stationData }) => {
  console.log("stationData", stationData);

  const handleClose = () => {
    setOpen(false);
  };

  const organizeData = (data) => {
    const tree = {};

    // Loop through the data and create a nested structure
    data.forEach((station) => {
      const { city, district, range, station_location } = station;

      if (!tree[city]) {
        tree[city] = { name: city, children: [] };
      }

      let cityNode = tree[city];
      let districtNode = cityNode.children.find((d) => d.name === district);
      if (!districtNode) {
        districtNode = { name: district, children: [] };
        cityNode.children.push(districtNode);
      }

      let rangeNode = districtNode.children.find((r) => r.name === range);
      if (!rangeNode) {
        rangeNode = { name: range, children: [] };
        districtNode.children.push(rangeNode);
      }

      rangeNode.children.push({ name: station_location });
    });

    return Object.values(tree);
  };

  if (stationData.length > 0) {
    treeData = organizeData(stationData);
  }

  const treeStyles = {
    nodes: {
      node: {
        circle: {
          fill: "#581919",
        },
        label: {
          fontSize: "18px",
        },
      },
      leafNode: {
        circle: {
          fill: "#581919",
        },
      },
    },
  };

  return (
    <>
      {stationData.length > 0 && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          //   maxWidth="lg" // Set dialog max-width to large
          //   fullWidth // Make dialog take full width
          PaperProps={{
            style: {
              width: "90%",
              margin: "auto",
              height: "90%",
            },
          }}
        >
          <DialogTitle>User Detail form</DialogTitle>
          <DialogContent>
            <div
              style={{
                width: "100%",
                height: "600px",
              }}
            >
              <ReactD3Tree
                data={treeData}
                renderCustomNodeElement={({ nodeDatum }) => (
                  <g>
                    <circle r={20} fill="#581919" />
                    <text fill="black" x="30" y="5">
                      {nodeDatum.name}
                    </text>
                  </g>
                )}
                orientation="vertical"
                styles={treeStyles}
                zoom={0.5}
                scaleExtent={{ min: 0.5, max: 1 }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <MaterialButton color="dark" onClick={handleClose}>
              Ok
            </MaterialButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default StationFlowChart;
