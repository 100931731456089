import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

import MaterialBox from "../../../../components/MaterialBox";
import {
  setMiniSidenav,
  setTransparentNavbar,
  useMaterialUIController,
} from "../../../../context";
import { navbarContainer, navbar, navbarRow } from "./styles";
import Breadcrumbs from "./components/Breadcrumbs";
import NotificationItem from "./components/NotificationItem";
import MaterialAvatar from "../../../../components/MaterialAvatar";
import { Avatar } from "@mui/material";
import stringAvatar from "../../../../functions/stringAvatar";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { BASE_URL } from "../../../../BaseUrl";
import MaterialTypography from "../../../../components/MaterialTypography";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [user, setUser] = useState({
    name: "", // This will hold the userId now
    picture: "",
    email: "",
    role: "",
  });
  const [pageState, setPageState] = useState({
    state: "init",
  });
  // console.log("pageState:", pageState);

  useEffect(() => {
    getUserId();
  }, []);

  const getUserId = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found.");
        return;
      }

      // console.log("token", token);

      const response = await fetch(`${BASE_URL}api/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      // console.log("resultuserId", result);

      if (result && result.userId) {
        setUser((prevReport) => ({
          ...prevReport,
          name: result.userId,
          role: result.role,
        }));
      } else {
        // console.log("No userId found in the response.");
      }
    } catch (error) {
      console.error("Error fetching userId:", error);
    }
    setPageState({
      state: "default",
    });
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const navigate = useNavigate();
  const open = Boolean(openMenu);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={
          <>
            {user.picture ? (
              <Avatar
                alt={user.name} // Display userId here as name
                src={user.picture}
                sx={{ width: 24, height: 24 }}
              />
            ) : (
              <MaterialAvatar
                size="sm"
                {...stringAvatar(user.name || user.email || "UNNAMED")} // Display userId here as name
              />
            )}
          </>
        }
        title={user.name || user.email} // Display userId here as name
      />
      <NotificationItem
        id="logout"
        onClick={signOut}
        icon={<LogoutIcon />}
        title={"Logout"}
      />
    </Menu>
  );

  const signOut = () => {
    navigate("/sign-in");
    localStorage.removeItem("token");
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        {pageState.state === "default" && (
          <>
            <MaterialBox
              color="inherit"
              mb={{ xs: 1, md: 0 }}
              sx={(theme) => navbarRow(theme, { isMini })}
            >
              <MaterialBox display="flex">
                <Icon
                  fontSize="medium"
                  onClick={handleMiniSidenav}
                  sx={{ cursor: "pointer" }}
                >
                  {miniSidenav ? <MenuOpenIcon /> : <MenuIcon />}
                </Icon>

                <MaterialBox ml={1}>
                  <Breadcrumbs
                    icon={<HomeIcon />}
                    title={route[route.length - 1]}
                    route={route}
                    light={light}
                  />
                </MaterialBox>
              </MaterialBox>
            </MaterialBox>
            <MaterialBox sx={(theme) => navbarRow(theme, { isMini })}>
              <MaterialTypography color="dark">{user.role}</MaterialTypography>

              {isMini ? null : (
                <>
                  <MaterialBox sx={(theme) => navbarRow(theme, { isMini })}>
                    <IconButton
                      onClick={handleOpenMenu}
                      size="small"
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <MaterialAvatar
                        alt={user.name} // Display userId here as name
                        src={user.picture}
                        sx={{ width: 35, height: 35 }}
                      />
                    </IconButton>
                    {renderMenu()}
                  </MaterialBox>
                </>
              )}
            </MaterialBox>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
