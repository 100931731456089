import React, { useEffect, useState } from "react";
import MaterialDataGrid from "../../../../components/MaterialDataGrid";
import {
  fetchData,
  fetchDataById,
  deleteData,
} from "../../../../BaseUrl";
import ConfirmationDialog from "../../../../components/Dialog/ConfirmationDialog";
import StationDetailFrom from "./StationDetailFrom";

const StationDataGrid = (result) => {
  const [stationData, setstationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [oneStationData, setOneStationData] = useState(null); 
  const [dialogOpen, setDialogOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false); 
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getStationData();
  }, []);

  useEffect(() => {
    getStationData();
  }, [result]);

  const getStationData = async () => {
    try {
      const data = await fetchData("api/station");
      setstationData(data.stations);
    } catch (error) {
      console.error("Error fetching crime data", error);
    } finally {
      setLoading(false);
    }
  };

  const onView = async (id) => {
    try {
      const item = await fetchDataById(`api/station/${id}`);
    } catch (error) {
      console.error(`Error fetching item with ID ${id}:`, error);
    }
  };

  const onEdit = async (id) => {
    try {
      const item = await fetchDataById(`api/station/${id}`);
      console.log("item", item.station)
      setOneStationData(item.station);
      setDialogOpen(true); 
      getStationData()
    } catch (error) {
      console.error(`Error fetching item with ID ${id}:`, error);
    }
  };

  const onDelete = (id) => {
    setDeleteId(id); 
    setOpenDialog(true); 
  };

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "station_id", headerName: "Station Id", width: 200 },
    { field: "station_location", headerName: "Station Name", width: 200, },
    { field: "range", headerName: "Station Range", width: 200, },
    { field: "district", headerName: "Station District", width: 200, },
    { field: "city", headerName: "Station City", width: 200, },
    { field: "latitude", headerName: "Latitude", width: 200 },
    { field: "longitude", headerName: "Longitude", width: 200 },
    { field: "actions", headerName: "Actions", width: 200 },
  ];

  const handleDialogConfirm = async () => {
    if (deleteId) {
      try {
        const result = await deleteData(`api/station/${deleteId}`);
        getStationData();
        setDeleteId(null);
      } catch (error) {
        console.error(`Error deleting item with ID ${deleteId}:`, error);
      }
    }
    setOpenDialog(false); 
  };

 
  const handleDialogCancel = () => {
    setDeleteId(null); 
    setOpenDialog(false); 
  };


  return (
    <div style={{ height: 400, width: "100%" }}>
      <MaterialDataGrid
        rows={stationData}
        columns={columns}
        loading={loading}
        // checkboxSelection={true}
        // onRowClick={(params) => console.log(params.row)}
        customHeight={400}
        isSelectable={true}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
      />
       {dialogOpen && oneStationData && (
         <StationDetailFrom
         oneStationData={oneStationData}
         open={dialogOpen}
         setOpen={setDialogOpen}
         getStationData={getStationData}
       />
      )}

      <ConfirmationDialog
        open={openDialog} 
        showCancel={true} 
        showConfirm={true} 
        title="Confirm Deletion"
        message="Are you sure you want to delete this crime type?" 
        onConfirm={handleDialogConfirm} 
        onCancel={handleDialogCancel} 
      />
    </div>
  );
};

export default StationDataGrid;
