import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialInput from "../../../../components/MaterialInput";
import MaterialSelect from "../../../../components/MasterSelect";
import { FormControl, FormHelperText } from "@mui/material";

const UserForm = ({ userReport, resources, handleUserReportDataChange, optiontype,optionhead,optionsubhead,optioncrimestatus }) => {
  const type = [
    {
      label: "Law & Order",
      value: "Law & Order",
    },
    {
      label: "Crime",
      value: "Crime",
    },
  ];

  const crimeTypes = {
    "Law & Order": [
      { value: "Human Body", label: "Human Body" },
    ],
    "Crime": [
      { value: "Property", label: "Property" },
    ],
  };
 


  const [subHead, setSubHead] = useState([]);

  useEffect(() => {

    console.log("userReport.crimeType", userReport.crimeType)
    if (userReport.crimeType.value === "Human Body") {
      setSubHead(humanBodyCrime);
    } else if (userReport.crimeType.value === "Property") {
      setSubHead(propertyCrime);
    } else {
      setSubHead([]);
    }
  }, [userReport.crimeType]);

 
  

  const humanBodyCrime = [
    {
      label: "Murder",
      value: "Murder",
    },
    {
      label: "Attempt to Murder",
      value: "Attempt to Murder",
    },
    {
      label: "Grievous Hurt",
      value: "Grievous Hurt",
    },
    {
      label: "Simple Hurt",
      value: "Simple Hurt",
    },
    {
      label: "Rioting",
      value: "Rioting",
    },
    {
      label: "Assault on Public Servant",
      value: "Assault on Public Servant",
    },
  ];

  const propertyCrime = [
    {
      label: "MFG",
      value: "MFG",
    },
    {
      label: "Dacoity",
      value: "Dacoity",
    },
    {
      label: "Roberry",
      value: "Roberry",
    },
    {
      label: "HB by Day",
      value: "HB by Day",
    },
    {
      label: "HB by Night",
      value: "HB by Night",
    },
    {
      label: "Chain Snatching",
      value: "Chain Snatching",
    },
    {
      label: "Mobile Snatching",
      value: "Mobile Snatching",
    },
    {
      label: "Automobile Theft",
      value: "Automobile Theft",
    },
    {
      label: "Major Theft",
      value: "Major Theft",
    },
    {
      label: "Other Theft",
      value: "Other Theft",
    },
  ];

  const crimestatus = [
    {
      label: "Rep",
      value: "Rep",
    },
    {
      label: "Det",
      value: "Det",
    },
    {
      label: "Con",
      value: "Con",
    },
    {
      label: "Acq",
      value: "Acq",
    },
    {
      label: "PT",
      value: "PT",
    },
    {
      label: "NTF",
      value: "NTF",
    },
    {
      label: "LUI",
      value: "LUI",
    },
    {
      label: "UI",
      value: "UI",
    },
    {
      label: "OD",
      value: "OD",
    },
  ];

  const filteredCrimeTypes =
  userReport.type && crimeTypes[userReport.type.value] ? crimeTypes[userReport.type.value] : [];

console.log("Filtered Crime Types: ", filteredCrimeTypes);
console.log("userReport.type: ", userReport.type);

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            User Id*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          disabled
          name="userId"
          placeholder="eg. 764234"
          value={userReport.userId}
          onChange={handleUserReportDataChange}
          error={userReport.userIdStatus === "error" ? true : false}
          success={userReport.userIdStatus === "success" ? true : false}
        />
        {userReport.userId === "error" && (
          <FormControl error>
            <FormHelperText>User Id should be in number</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Name of the user*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="userName"
          placeholder="eg. Ajith"
          value={userReport.userName}
          onChange={handleUserReportDataChange}
          error={userReport.userNameStatus === "error" ? true : false}
          success={userReport.userNameStatus === "success" ? true : false}
        />
        {userReport.userName === "error" && (
          <FormControl error>
            <FormHelperText>user name should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Type*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="type"
          value={userReport.type}
          onChange={handleUserReportDataChange}
          options={optiontype}
         
          // {type?.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          error={userReport.typeStatus === "error" ? true : false}
          success={userReport.typeStatus === "success" ? true : false}
          // isClearable
        />
        {userReport.type === "error" && (
          <FormControl error>
            <FormHelperText>Police Role should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Head*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="crimeType"
          value={userReport.crimeType}
          customStyles={{ optionPadding: 20 }}
          onChange={handleUserReportDataChange}
          // options={resources?.crimeType.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          // options={filteredCrimeTypes?.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          options={optionhead}
       
          error={userReport.crimeTypeStatus === "error" ? true : false}
          success={userReport.crimeTypeStatus === "success" ? true : false}
          // isClearable
        />
        {userReport.crimeType === "error" && (
          <FormControl error>
            <FormHelperText>Crime type should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>

      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Sub Head*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="subHead"
          value={userReport.subHead}
          onChange={handleUserReportDataChange}
          options={optionsubhead}
          // {subHead?.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          error={userReport.subHeadStatus === "error" ? true : false}
          success={userReport.subHeadStatus === "success" ? true : false}
          // isClearable
        />
        {userReport.subHead === "error" && (
          <FormControl error>
            <FormHelperText>Police Role should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>

      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Crime Status*
          </MaterialTypography>
        </MaterialBox>
        <MaterialSelect
          name="crimestatus"
          value={userReport.crimestatus}
          onChange={handleUserReportDataChange}
          options={optioncrimestatus}
          // {crimestatus?.map((value) => {
          //   return {
          //     value: value.value,
          //     label: value.label,
          //   };
          // })}
          error={userReport.crimestatusStatus === "error" ? true : false}
          success={userReport.crimestatusStatus === "success" ? true : false}
          // isClearable
        />
        {userReport.subHead === "error" && (
          <FormControl error>
            <FormHelperText>Police Role should be in alphabets</FormHelperText>
          </FormControl>
        )}
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <MaterialBox lineHeight={0} display="inline-block">
          <MaterialTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Reported crime details*
          </MaterialTypography>
        </MaterialBox>
        <MaterialInput
          name="reportCrime"
          placeholder="eg. Reporting about the crime details"
          value={userReport.reportCrime}
          onChange={handleUserReportDataChange}
          error={userReport.reportcrimestatus === "error" ? true : false}
          success={userReport.reportcrimestatus === "success" ? true : false}
          multiline
          rows={5}
        />
        {userReport.reportCrime === "error" && (
          <FormControl error>
            <FormHelperText>
              Reported crime details should be in alphabets
            </FormHelperText>
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
};

export default UserForm;
