// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Box, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import PageLayout from "../../layoutcontainers/PageLayout";
import { useMaterialUIController } from "../../context";

function SigninLayout({ header, title, description, illustration, children, headlogo }) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return (
        <PageLayout background="white" height='100%' showNavbar disableFooter>
            <Grid
                container
                sx={{
                    backgroundColor: ({ palette: { background, white } }) =>
                        darkMode ? background.default : white.main,
                }}
            >
                <Grid size={{ xs: 12, lg: 6 }}>
                    <img src={illustration} alt="Image not loaded"
                        style={{
                            marginLeft: '15px',
                            marginTop: '15px',
                            width: "calc(100% - 2rem)",
                            height: "calc(100vh - 2rem)",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                            borderRadius: '15px'
                        }}
                    />
                </Grid>
                <Grid size={{ xs: 11, sm: 8, md: 6, lg: 4, xl: 3 }} sx={{ mx: "auto" }}>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' textAlign='center' >
                        <Box display="flex" justifyContent="center" mt={25} >
                            {/* <img src={"https://www.keelis.com/img/logo80x80.png"} alt="Image not loaded"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    borderRadius: '15px'
                                }}
                            /> */}
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" >
                        <img src={headlogo} alt="Image not loaded"
                                style={{
                                    // marginLeft: '15px',
                                    // marginTop: '15px',
                                    width: "35%",
                                    // height: "calc(100vh - 2rem)",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    // borderRadius: '15px'
                                }}
                            />
                            <Typography variant="h4" fontWeight="bold" color='black'>
                                {title}
                            </Typography>
                        </Box>
                        <Box>{children}</Box>
                    </Box>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

// Typechecking props for the SigninLayout
SigninLayout.propTypes = {
    header: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node.isRequired,
    illustration: PropTypes.string,
};

export default SigninLayout;
