import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { setMiniSidenav, useMaterialUIController } from "./context";
import { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./assest/theme";
import Sidenav from "./layoutcontainers/DashboardLayout/components/Sidebar";
import routes from "./routes";
import logo from "../src/assest/crime-logo.png";
import { BASE_URL } from "./BaseUrl";

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    picture: "",
    email: "",
    role:" "
  });
 
  const [routesarray, setroutesarray] = useState([]);
  const [getrecord, setgetrecord] = useState([]);
 

  // const [token, setToken] = useState(null);

  const storedToken = localStorage.getItem("token");

  // if (!storedToken) {
  //   if (!storedToken) {
  //     if (!window.location.pathname.includes("/sign-in")) {
  //       alert("Token has expired. Sign In Please...");
  //       window.location.href = "/sign-in";
  //     }
  //   }
  // }

  useEffect(() => {
    if (!storedToken) {
      if (!storedToken) {
       
          if (!window.location.pathname.includes("/sign-in")) {
            alert("Session Time out. Please Login to continue...");
            window.location.href = "/sign-in";
          }
        }
    }
  }, [!storedToken]);
 
  const token = localStorage.getItem("token");

  useEffect(() => {
    getUserId();
  }, [token]);

 

  const getUserId = async () => {
    try {
      // const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found.");
        return;
      }
      const response = await fetch(`${BASE_URL}api/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("response", response);
      const result = await response.json();
      // console.log("resultuserId", result);
      if (!response.messaeg) {
        if (result && result.userId) {
          setUser((prevReport) => ({
            ...prevReport,
            name: result.userId, // Set the userId as the 'name'
            role:result.role
          }));
          setgetrecord(getrecord)
        } else {
          // console.log("No userId found in the response.");
        }
      } else {
        alert("noo token");
      }
    } catch (error) {
      console.error("Error fetching userId:", error);
    }
  };

  const signOut = () => {
    localStorage.removeItem("token");
    navigate("/sign-in");
  };

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if((user.role=="DC")|| (user.role=="AC") || (user.role=="Commissioner"))
   {
      let filterroute= routes.filter(x=>x.name=='Abstract'|| x.name=='Map View')
      setroutesarray(filterroute)
   }
   else
   {
     setroutesarray(routes)
   }
  //  setroutesarray(routes)
    

   }, [user]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={logo}
            // brandName="Keelis"
            mininav={miniSidenav}
            routes={routesarray}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}

      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
