import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationData = ({ latitude, longitude }) => {
  console.log("latitude, longitude", latitude, longitude)
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Replace with your API key

  // This effect runs whenever latitude or longitude changes
  useEffect(() => {
    if (latitude && longitude) {
      getAddressFromCoordinates(latitude, longitude);
    }
  }, [latitude, longitude]);

  const getAddressFromCoordinates = async (lat, lng) => {
    if (!lat || !lng) {
      setError('Invalid latitude or longitude.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      );

      if (response.data.status === 'OK') {
        const formattedAddress = response.data.results[0]?.formatted_address;
        setAddress(formattedAddress || 'Address not found');
      } else {
        setError('Unable to fetch the address.');
      }
    } catch (err) {
      setError('Error fetching data from Google Maps API.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>

      {loading && <p>Loading...</p>}
      {address && !loading && <p>Address: {address}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default LocationData;
