/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import MaterialTypography from "../../components/MaterialTypography";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import MaterialBox from "../../components/MaterialBox";
import MaterialButton from "../../components/MaterialButton";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CrimeForm from "./components/crimeform";
import UserForm from "./components/userform";
import { useLocation, useNavigate } from "react-router-dom";
import {
  postData,
  fetchData,
  BASE_URL,
  fetchDataById,
  putData,
  GetALLCity,
  Getdistrictofcity,
  Getrangeofdistrict,
  GetstationRange,
  GetALLtype
} from "../../BaseUrl";
import CrimeDataBulkUpload from "./components/crimeDataBulkUpload";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import bgImage from "../../assest/bg-image.png";

const CrimeReportingForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Crime Reporting Form", "User Reporting Form"];
  const [station, setStation] = useState([{ value: "", label: "" }]);
  const [resources, setResources] = useState({
    crimeType: [{ value: "", label: "" }],
  });
  const [crimeReport, setCrimeReport] = useState({
    incidentId: "",
    locationlatitude: "",
    locationlongitude: "",
    crimeDate: "",
    crimeTime: "",
    // station: "",
    investigated: "",
    jurisdiction: "",
    redZone: "No",
    city: "",
    District: "",
    range: "",


    selectedvaluestation: "",
    incidentIdStatus: "unchecked",
    locationlatitudeStatus: "unchecked",
    locationlongitudeStatus: "unchecked",
    crimeDateStatus: "unchecked",
    crimeTimeStatus: "unchecked",
    // stationStatus: "unchecked",
    investigatedStatus: "unchecked",
    jurisdictionStatus: "unchecked",
    cityStatus: "unchecked",
    DistrictStatus: "unchecked",
    rangeStatus: "unchecked",
    selectedvaluestationStatus: "unchecked",

  });

  const [userReport, setUserReport] = useState({
    userId: "",
    userName: "",
    reportCrime: "",
    crimeType: "",
    type: "",
    subHead: "",
    crimestatus: "",
    userIdStatus: "unchecked",
    userNameStatus: "uncheked",
    reportcrimestatus: "uncheked",
    crimeTypeStatus: "uncheked",
    typeStatus: "unchecked",
    subHeadStatus: "unchecked",
    crimestatusStatus: "unchecked",
  });

  const [stationselect, setstationselect] = useState([]);
  const [rangeselect, setrangeselect] = useState([]);
  const [districtselect, setdistrictselect] = useState([]);
  const [cityselect, setcityselect] = useState([]);

  const [crimestatuselect, setcrimestatuselect] = useState([])
  const [subheadselect, setsubheadselect] = useState([])
  const [headselect, setheadselect] = useState([])
  const [typeoption, settypeoption] = useState([])

  useEffect(() => {
    if (activeStep === 0) {
      if (!id) {
        getIncidentId();
      }
      getUserId();
    }
  }, [activeStep]);

  useEffect(() => {
    if (!id) {
      getIncidentId();
    }
    getStation();
    getUserId();
    getCrimeType();
    getallcity();
    getalltype()
  }, []);

  useEffect(() => {
    if (id) {
      getCrimeDetailsById(id);
    }
  }, [id]);

  //filter crime master
  useEffect(() => {
    getallheadbasedontype(userReport.type)

  }, [userReport.type]);
  useEffect(() => {
    getallsubheadbasedonHead(userReport.crimeType)

  }, [userReport.crimeType]);
  useEffect(() => {
    getallcrimestatusbasedonsubhead(userReport.subHead)

  }, [userReport.subHead]);

  const getalltype = async () => {
    try {
      const result = await GetALLtype("api/TypeCrime");
      console.log("resultaa", result)
      if (result.length > 0) {
        settypeoption(result)
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallheadbasedontype = async (value) => {

    try {
      const result = await Getdistrictofcity(`api/crimeTypes/name?filterValue=${value.label}`);

      if (result.length > 0) {
        setheadselect(result)
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallsubheadbasedonHead = async (value) => {
    console.log("value", value)
    try {
      const result = await Getrangeofdistrict(`api/crimeTypes/head?filterValue=${value.label}`);
      console.log("resulr", result)
      if (result.length > 0) {
        setsubheadselect(result)
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallcrimestatusbasedonsubhead = async (value) => {

    try {
      const result = await GetstationRange(`api/crimeTypes/subhead?filterValue=${value.label}`);

      if (result.length > 0) {
        setcrimestatuselect(result)
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };
  //filter crime master

  // PoliceRole: { value: oneUserData.role, label: oneUserData.role} || "",
  const getCrimeDetailsById = async (id) => {
    try {
      const result = await fetchData(`api/crime/${id}`);
      // console.log("result45545", result)
      if (result && result.incident_id) {
        setCrimeReport((prevReport) => ({
          ...prevReport,
          incidentId: result.incident_id || "",
          locationlatitude: result.latitude || "",
          locationlongitude: result.longitude || "",
          crimeDate: result.date || "",
          crimeTime: result.time || "",
          station: { value: result.station, label: result.station } || "",
          city: { value: result.city, label: result.city } || "",
          District: { value: result.district, label: result.district } || "",
          range: { value: result.range, label: result.range } || "",
          selectedvaluestation:
            {
              value: result.station_location,
              label: result.station_location,
            } || "",
          investigated: result.investigated_by || "",
          jurisdiction: result.station_jurisdiction || "",
          redZone: result.red_flag_zone || "No",
          incidentIdStatus: result.incident_id ? "checked" : "unchecked",
          locationlatitudeStatus: result.latitude ? "success" : "unchecked",
          locationlongitudeStatus: result.longitude ? "success" : "unchecked",
          crimeDateStatus: result.date ? "success" : "unchecked",
          crimeTimeStatus: result.time ? "success" : "unchecked",
          stationStatus: result.station ? "success" : "unchecked",
          investigatedStatus: result.investigated_by ? "success" : "unchecked",
          jurisdictionStatus: result.station_jurisdiction
            ? "success"
            : "unchecked",
          cityStatus: result.city ? "success" : "unchecked",
          DistrictStatus: result.district ? "success" : "unchecked",
          rangeStatus: result.range ? "success" : "unchecked",
          selectedvaluestationStatus: result.station_location
            ? "success"
            : "unchecked",
        }));

        setUserReport((prevUserReport) => ({
          ...prevUserReport,
          userId: result.userId || "",
          userName: result.userName || "",
          reportCrime: result.reportedCrimeDetails || "",
          crimeType: { value: result.crimeType, label: result.crimeType } || "",
          type: { value: result.type, label: result.type } || "",
          subHead: { value: result.subHead, label: result.subHead } || "",
          crimestatus: { value: result.crimestatus, label: result.crimestatus } || "",
          userIdStatus: result.userId ? "checked" : "unchecked",
          userNameStatus: result.userName ? "success" : "unchecked",
          reportcrimestatus: result.reportedCrimeDetails
            ? "success"
            : "unchecked",
          crimeTypeStatus: result.crimeType ? "success" : "unchecked",
          typeStatus: result.crimeType ? "success" : "unchecked",
          subHeadStatus: result.crimeType ? "success" : "unchecked",
          crimestatusStatus: result.crimeType ? "success" : "unchecked",
        }));
      }
    } catch (error) {
      console.error("Error fetching crime details by ID:", error);
    }
  };

  const getUserId = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No token found.");
        return;
      }

      const response = await fetch(`${BASE_URL}api/me`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (result && result.userId) {
        setUserReport((prevReport) => ({
          ...prevReport,
          userId: result.userId,
          userIdStatus: "checked",
        }));
      } else {
        // console.log("No userId found in the response.");
      }
    } catch (error) {
      console.error("Error fetching userId:", error);
    }
  };

  const getIncidentId = async () => {
    try {
      const result = await fetchData("api/getIncidentId");

      if (result && result.incident_id) {
        setCrimeReport((prevReport) => ({
          ...prevReport,
          incidentId: result.incident_id,
          incidentIdStatus: "checked",
        }));
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getStation = async () => {
    try {
      const result = await fetchData("api/station");
      const stationData = result.stations.map((station) => ({
        value: station.station_location,
        label: station.station_location,
      }));
      setStation([...stationData]);
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  useEffect(() => {
    getalldistrictbasedoncity(crimeReport.city);
  }, [crimeReport.city]);
  useEffect(() => {
    getallRangebasedonDistrict(crimeReport.District);
  }, [crimeReport.District]);
  useEffect(() => {
    getallstationbasedonRange(crimeReport.range);
  }, [crimeReport.range]);

  const getallcity = async () => {
    try {
      const result = await GetALLCity("/api/stations");
      //  console.log("result",result)
      console.log("result", result);
      if (result.stations.length > 0) {
        setcityselect(result.stations);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getalldistrictbasedoncity = async (value) => {
    // console.log("value",value)
    try {
      const result = await Getdistrictofcity(
        `/api/stations/city?filterValue=${value.label}`
      );

      //  console.log("result",result)
      if (result.length > 0) {
        setdistrictselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallRangebasedonDistrict = async (value) => {
    // console.log("value",value)
    try {
      const result = await Getrangeofdistrict(
        `/api/stations/district?filterValue=${value.label}`
      );

      //  console.log("result",result)
      if (result.length > 0) {
        setrangeselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getallstationbasedonRange = async (value) => {
    // console.log("value",value)
    try {
      const result = await GetstationRange(
        `/api/stations/range?filterValue=${value.label}`
      );

      //  console.log("result",result)
      if (result.length > 0) {
        setstationselect(result);
      }
    } catch (error) {
      console.error("Error fetching incident ID:", error);
    }
  };

  const getCrimeType = async () => {
    try {
      const result = await fetchData("api/crimeType");
      // console.log("result", result);

      const crimeTypeData = result.map((crimeType) => ({
        value: crimeType.name,
        label: crimeType.name,
      }));
      setResources((prevState) => ({
        ...prevState,
        crimeType: crimeTypeData, // Set the crimeType property of the state
      }));
    } catch (error) {
      console.error("Error fetching crime types:", error);
    }
  };

  const handleCrimeReportDataChange = (event, selectEvent) => {
    const changeAllowed = true;
    let crimeState = { ...crimeReport };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "city") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          crimeState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          crimeState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "District") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          crimeState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          crimeState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "range") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          crimeState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          crimeState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "selectedvaluestation") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          crimeState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          crimeState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }
      crimeState[selectEvent.name] = event;
    } else {
      switch (event.target.name) {
        case "incidentId":
          {
            const numLengthRegex = new RegExp("^([0-9])");

            if (numLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "locationlatitude":
          {
            const numLengthRegex = new RegExp("^([0-9])");

            if (numLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "locationlongitude":
          {
            const numLengthRegex = new RegExp("^([0-9])");

            if (numLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "crimeDate":
          {
            const dateLengthRegex = new RegExp(
              "^[a-zA-Z0-9 !@#$%^&*()_+{}|:\"<>?`\\[\\]\\\\;,./'-=~]{10,150}$"
            );

            if (dateLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "crimeTime":
          {
            const timeLengthRegex = new RegExp(
              /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/
            );

            if (timeLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "station":
          {
            const alphaNLengthRegex = new RegExp("^([a-zA-Z ]{1,50})$");
            if (alphaNLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "investigated":
          {
            const alphaNLengthRegex = new RegExp("^([a-zA-Z ]{1,50})$");

            if (alphaNLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "jurisdiction":
          {
            const alphaNLengthRegex = new RegExp("^([a-zA-Z ]{1,50})$");

            if (alphaNLengthRegex.test(event.target.value?.trim())) {
              crimeState[event.target.name + "Status"] = "success";
            } else {
              crimeState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
      }
      if (changeAllowed) {
        crimeState[event.target.name] = event.target.value;
      }
    }
    setCrimeReport(crimeState);
  };
  const handleUserReportDataChange = (event, selectEvent) => {
    const changeAllowed = true;
    let userState = { ...userReport };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "crimeType") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "crimestatus") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "type") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      } else if (selectEvent.name === "subHead") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }
      userState[selectEvent.name] = event;
    } else {
      switch (event.target.name) {
        case "userId":
          {
            const numLengthRegex = new RegExp("^[A-Za-z0-9]");
            if (numLengthRegex.test(event.target.value?.trim())) {
              userState[event.target.name + "Status"] = "success";
            } else {
              userState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "userName":
          {
            const alphaNLengthRegex = new RegExp("^([a-zA-Z. ]{1,50})$");
            if (alphaNLengthRegex.test(event.target.value?.trim())) {
              userState[event.target.name + "Status"] = "success";
            } else {
              userState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
        case "reportCrime":
          {
            const alphaNLengthRegex = new RegExp("^([A-Za-z0-9 ]{1,150})$");

            if (alphaNLengthRegex.test(event.target.value?.trim())) {
              userState[event.target.name + "Status"] = "success";
            } else {
              userState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
      }
      if (changeAllowed) {
        userState[event.target.name] = event.target.value;
      }
    }
    setUserReport(userState);
  };

  const handleRedZoneChange = (e) => {
    setCrimeReport((prevReport) => ({
      ...prevReport,
      redZone: e.target.value,
    }));
  };

  // Function to move the next step
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  // Function to move the previous step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async () => {
    console.log("crimeReport", crimeReport, userReport)
    const crimeData = {
      incident_id: crimeReport.incidentId,
      latitude: crimeReport.locationlatitude,
      longitude: crimeReport.locationlongitude,
      date: crimeReport.crimeDate,
      time: crimeReport.crimeTime,
      city: crimeReport.city.value,
      district: crimeReport.District.value,
      range: crimeReport.range.value,
      station_location: crimeReport.selectedvaluestation.value,
      station: crimeReport.selectedvaluestation.value,
      investigated_by: crimeReport.investigated,
      station_jurisdiction: crimeReport.jurisdiction,
      red_flag_zone: crimeReport.redZone,
      userId: userReport.userId,
      userName: userReport.userName,
      crimeType: userReport.crimeType.value,
      reportedCrimeDetails: userReport.reportCrime,
      type: userReport.type.value,
      subHead: userReport.subHead.value,
      crimestatus: userReport.crimestatus.value,
    };

    if (id) {
      try {
        // console.log("crimeDataPut", crimeData);
        const result = await putData(`api/crime/${id}`, crimeData);
      } catch (err) {
        console.error("Error posting data:", err);
      }
      navigate("/map-view");
    } else {
      try {
        const result = await postData("api/crime", crimeData);
      } catch (err) {
        console.error("Error posting data:", err);
      }
      setActiveStep(0);
      navigate("/crime-reporting-form");
    }

    setCrimeReport({
      incidentId: "",
      locationlatitude: "",
      locationlongitude: "",
      crimeDate: "",
      crimeTime: "",
      investigated: "",
      jurisdiction: "",
      redZone: "No",
      city: "",
      District: "",
      range: "",
      selectedvaluestation: "",
      incidentIdStatus: "unchecked",
      locationlatitudeStatus: "unchecked",
      locationlongitudeStatus: "unchecked",
      crimeDateStatus: "unchecked",
      crimeTimeStatus: "unchecked",
      investigatedStatus: "unchecked",
      jurisdictionStatus: "unchecked",
      cityStatus: "unchecked",
      DistrictStatus: "unchecked",
      rangeStatus: "unchecked",
      selectedvaluestationStatus: "unchecked",

    });

    setUserReport({
      userId: "",
      userName: "",
      reportCrime: "",
      crimeType: "",
      type: "",
      subHead: "",
      crimestatus: "",
      userIdStatus: "unchecked",
      userNameStatus: "uncheked",
      reportcrimestatus: "uncheked",
      crimeTypeStatus: "uncheked",
      typeStatus: "unchecked",
      subHeadStatus: "unchecked",
      crimestatusStatus: "unchecked",
    });
  };

  const isCrimeFormIncomplete = Object.keys(crimeReport).some((key) => {
    const value = crimeReport[key];
    return value === "" || value === "unchecked";
  });

  const isUserFormIncomplete = Object.keys(userReport).some((key) => {
    const value = userReport[key];
    return value === "" || value === "unchecked";
  });

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogConfirm = async () => {
    // Your submit logic goes here, for example:
    await handleSubmit(); // Calling the existing handleSubmit function for submitting the form
    setOpenDialog(false); // Close the dialog after confirming
  };

  // Function to handle dialog cancellation (just close the dialog)
  const handleDialogCancel = () => {
    setOpenDialog(false); // Simply close the dialog without submitting
  };

  // Function to trigger the confirmation dialog when the user clicks submit
  const handleSubmitClick = () => {
    setOpenDialog(true); // Open the dialog when the user clicks "Submit"
  };

  return (
    <DashboardLayout>
      <ConfirmationDialog
        open={openDialog} // Controls whether the dialog is shown
        showCancel={true} // Show the cancel button
        showConfirm={true} // Show the confirm button
        title="Confirm Submission" // Title of the dialog
        message="Are you sure you want to submit the form?" // Message in the dialog
        onConfirm={handleDialogConfirm} // Function to call when "Confirm" is clicked
        onCancel={handleDialogCancel} // Function to call when "Cancel" is clicked
      />
      <Card
        sx={{
          backgroundColor: "#FFEED7",
        }}
      >
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="bold">
                  Reporting Form
                </MaterialTypography>
              </Stack>
            </Stack>
          }
        />

        <MaterialBox
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            padding: "10px",
          }}
        >
          <CrimeDataBulkUpload getIncidentId={getIncidentId} />
        </MaterialBox>

        <CardContent
          sx={{
            minHeight: "90vh",
            position: "relative",
          }}
        >
          <img
            src={bgImage}
            alt="background"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              opacity: 0.2,
            }}
          />
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{
              // backgroundColor: 'red',
              padding: "0px",
              marginTop: "40px",
              marginBottom: "20px",
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <CrimeForm
              crimeReport={crimeReport}
              station={station}
              cityselect={cityselect}
              districtselect={districtselect}
              rangeselect={rangeselect}
              stationselect={stationselect}
              handleCrimeReportDataChange={handleCrimeReportDataChange}
              handleRedZoneChange={handleRedZoneChange}
            />
          )}
          {activeStep === 1 && (
            <UserForm
              userReport={userReport}
              resources={resources}
              handleUserReportDataChange={handleUserReportDataChange}
              optiontype={typeoption}
              optionhead={headselect}
              optionsubhead={subheadselect}
              optioncrimestatus={crimestatuselect}

            />
          )}
        </CardContent>

        <CardActions>
          <MaterialBox
            p={2}
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            {activeStep === 0 ? (
              <MaterialBox />
            ) : (
              <MaterialButton
                variant="gradient"
                color="dark"
                style={{ maxWidth: "100px", minWidth: "100px" }}
                onClick={handleBack}
              >
                <NavigateBeforeIcon />
                prev
              </MaterialButton>
            )}
            {activeStep === steps.length - 1 ? (
              <MaterialButton
                variant="gradient"
                color="dark"
                onClick={handleSubmitClick}
                disabled={isUserFormIncomplete}
              >
                submit
              </MaterialButton>
            ) : (
              <MaterialButton
                variant="gradient"
                color="dark"
                onClick={handleNext}
                style={{ maxWidth: "100px", minWidth: "100px" }}
                disabled={isCrimeFormIncomplete}
              >
                next
                <NavigateNextIcon />
              </MaterialButton>
            )}
          </MaterialBox>
        </CardActions>
      </Card>
    </DashboardLayout>
  );
};

export default CrimeReportingForm;
