import { SvgIcon } from "@mui/material";
import Dashboard from "./pages/dashboard";
import Employeeonboarding from "./pages/onboarding/employeeonboarding";
import EmployeeManagement from "./pages/employeemanagement";
import ManagerManagement from "./pages/managermanagement";
import Department from "./pages/department";
import Designation from "./pages/designation";
import Payroll from "./pages/payroll";
import LeaveManagement from "./pages/leavemanagement";
import RegularizationRequest from "./pages/regularizationrequest";
import WFHManagemnt from "./pages/wfhmanagement";
import Attendance from "./pages/attendance";

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddEmployee from "./pages/employeemanagement/components/AddEmployee";
import Signin from "./pages/authentication/signin";
import { Shop } from "@mui/icons-material";
import AddManager from "./pages/managermanagement/components/AddManager";
import CrimeReportingForm from "./pages/crimereportingform";
import CrimeFilter from "./pages/crimereportingform/components/crimMapingView";
import CrimeMaster from "./pages/crimereportingform/components/crimeMaster";
import UserMaster from "./pages/crimereportingform/components/userMaster";
import PublicIcon from '@mui/icons-material/Public';
import StationMaster from "./pages/crimereportingform/components/stationMaster";
import CrimeReportTable from "./pages/crimereportingform/components/crimeDetaile/CrimeReportTable";
import CrimeDashboard from "./pages/crimereportingform/components/CrimeDashboard/CrimeDashboard";
import CrimeSummery from "./pages/crimereportingform/components/crimeSummery/CrimeSummery";


const routes = [
    {
        name: "Authentication",
        key: "authentication",
        collapse: [
            {
                name: "Sign in",
                key: "sign-in",
                route: "/sign-in",
                component: <Signin />,
            },
        ],
    },
    // {
    //     type: "collapse",
    //     icon: <PublicIcon />,
    //     name: "Crime Dashboard",
    //     key: "crime-dashboard",
    //     route: "/crime-dashboard",
    //     component: <CrimeDashboard />,
    //     noCollapse: true,
    //     protected: true,
    // },
    {
        type: "collapse",
        icon: <PublicIcon />,
        name: "Map View",
        key: "map-view",
        route: "/map-view",
        component: <CrimeFilter />,
        noCollapse: true,
        protected: true,
    },
    {
        type: "collapse",
        icon: <PersonIcon />,
        name: "Abstract",
        key: "crime-Summery",
        route: "/crime-Summery",
        component: <CrimeSummery />,
        noCollapse: true,
        protected: true,
    },

    {
        type: "collapse",
        icon: <ManageAccountsIcon />,
        name: "Crime Reporting Form",
        key: "crime-reporting-form",
        route: "/crime-reporting-form",
        component: <CrimeReportingForm />,
        noCollapse: true,
        protected: true,
    },
    {
        type: "collapse",
        icon: <ManageAccountsIcon />,
        name: "Crime Master",
        key: "crime-Master",
        route: "/crime-Master",
        component: <CrimeMaster />,
        noCollapse: true,
        protected: true,
    },
    {
        type: "collapse",
        icon: <PersonIcon />,
        name: "User Master",
        key: "user-Master",
        route: "/user-Master",
        component: <UserMaster />,
        noCollapse: true,
        protected: true,
    },
    {
        type: "collapse",
        icon: <PersonIcon />,
        name: "station Master",
        key: "station-Master",
        route: "/station-Master",
        component: <StationMaster />,
        noCollapse: true,
        protected: true,
    },{
        type: "collapse",
        icon: <PersonIcon />,
        name: "Crime Details",
        key: "crime-Details",
        route: "/crime-Details",
        component: <CrimeReportTable />,
        noCollapse: true,
        protected: true,
    },
    
]
export default routes;