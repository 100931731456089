/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Stack,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import MaterialInput from "../../../../components/MaterialInput";
import MaterialButton from "../../../../components/MaterialButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { fetchData, postData, putData } from "../../../../BaseUrl";
import MaterialSelect from "../../../../components/MasterSelect";
import zIndex from "@mui/material/styles/zIndex";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDataForm = ({ oneUserData, open, setOpen, getUserData }) => {

  const tamilNaduPoliceHierarchy = [
    {
      label: "DC",
      value: "DC",
    },
    {
      label: "Commissioner",
      value: "Commissioner",
    },
    {
      label: "AC",
      value: "AC",
    },
    {
      label: "Inspector",
      value: "Inspector",
    },
  ];

  const [error, seterror] = useState()

  const [userDetails, setUserDetails] = useState({
    username: "",
    userId: "",
    email: "",
    phone: "",
    password: "",
    PoliceRole: "",
    StationCity: "",
    usernameStatus: "unchecked",
    userIdStatus: "unchecked",
    emailStatus: "unchecked",
    phoneStatus: "unchecked",
    passwordStatus: "unchecked",
    PoliceRoleStatus: "unchecked",
    StationCityStatus: "unchecked",
  });

  console.log("userDetails", userDetails);

  useEffect(() => {
    if (oneUserData) {
      setUserDetails({
        username: oneUserData.userName || "",
        userId: oneUserData.userId || "",
        email: oneUserData.email || "",
        phone: oneUserData.phone || "",
        usernameStatus: "success",
        userIdStatus: "success",
        emailStatus: "success",
        phoneStatus: "success",
        passwordStatus: "success",
        PoliceRoleStatus: "success",
        StationCityStatus: "success",
        PoliceRole: { value: oneUserData.role, label: oneUserData.role} || "",
        StationCity: { value: oneUserData.station_location, label: oneUserData.station_location} || "",
      });
    }
  }, [oneUserData]);

  const handleUserDataChange = (event, selectEvent) => {
    const changeAllowed = true;
    let userState = { ...userDetails };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "PoliceRole") {
        const lengthRegex = new RegExp("^[A-Za-z0-9 ]+");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }else if (selectEvent.name === "StationCity") {
        const lengthRegex = new RegExp("^[A-Za-z0-9 ]+");
        if (lengthRegex.test(event?.label) === true) {
          userState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          userState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }
      userState[selectEvent.name] = event;
    } else if (event?.target) {
      switch (event.target.name) {
        case "username":
          const usernameRegex = new RegExp("^[a-zA-Z0-9 ]+$");
          userState[event.target.name + "Status"] = usernameRegex.test(
            event.target.value?.trim()
          )
            ? "success"
            : event.target.value === ""
            ? "unchecked"
            : "error";
          break;

        case "userId":
          const userIdRegex = new RegExp("^[a-zA-Z0-9-]+$");
          userState[event.target.name + "Status"] = userIdRegex.test(
            event.target.value?.trim()
          )
            ? "success"
            : event.target.value === ""
            ? "unchecked"
            : "error";
          break;

        case "email":
          const emailRegex = new RegExp(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
          );
          userState[event.target.name + "Status"] = emailRegex.test(
            event.target.value?.trim()
          )
            ? "success"
            : event.target.value === ""
            ? "unchecked"
            : "error";
          break;

        case "phone":
          const phoneRegex = new RegExp("^[0-9]{10}$");
          userState[event.target.name + "Status"] = phoneRegex.test(
            event.target.value?.trim()
          )
            ? "success"
            : event.target.value === ""
            ? "unchecked"
            : "error";
          break;

        case "password":
          const passwordRegex = new RegExp(".{6,}");
          userState[event.target.name + "Status"] = passwordRegex.test(
            event.target.value?.trim()
          )
            ? "success"
            : event.target.value === ""
            ? "unchecked"
            : "error";
          break;

        default:
          break;
      }

      // If change is allowed, update the value
      if (changeAllowed) {
        userState[event.target.name] = event.target.value;
      }
    }

    // Update the state with the new user details
    setUserDetails(userState);
  };

  // Submit form (API call) function
  const handleFormSubmit = async () => {
    let a=validate()
    if(a==true)
    {
      const userData = {
        userName: userDetails.username,
        userId: userDetails.userId,
        email: userDetails.email,
        phone: userDetails.phone,
        password: userDetails.password,
        station_location: userDetails.StationCity.value,
        role: userDetails.PoliceRole.value
      };
    if (oneUserData) {
      try {
        const result = await putData(`api/user/${oneUserData.id}`, userData);
        // console.log("Form submitted successfully:", result);
        handleClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      getUserData();
    } else {
      try {
        const result = await postData("api/user", userData);
        // console.log("Form submitted successfully:", result);
        handleClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
    }
    else{
      seterror("All fields are mandetory")
 
      setTimeout(() => {
        seterror(" ")
      }, 3000);
    }

   


    setUserDetails({
      username: "",
      userId: "",
      email: "",
      phone: "",
      password: "",
      usernameStatus: "unchecked",
      userIdStatus: "unchecked",
      emailStatus: "unchecked",
      phoneStatus: "unchecked",
      passwordStatus: "unchecked",
      PoliceRole: "",
      PoliceRoleStatus: "unchecked",
      StationName: "",
      StationCity: "",
      StationDistrict: "",
      StationRange: "",
      StationNameStatus: "unchecked",
      StationCityStatus: "unchecked",
      StationDistrictStatus: "unchecked",
      StationRangeStatus: "unchecked",
    });
  };

  const isFormValid = () => {
    return (
      userDetails.usernameStatus === "success" &&
      userDetails.userIdStatus === "success" &&
      userDetails.emailStatus === "success" &&
      userDetails.phoneStatus === "success" &&
      userDetails.passwordStatus === "success" &&
      userDetails.PoliceRoleStatus === "success" &&
      userDetails.StationCityStatus === "success" &&
      userDetails.username.trim() !== "" &&
      userDetails.userId.trim() !== "" &&
      userDetails.email.trim() !== "" &&
      userDetails.phone.trim() !== "" &&
      userDetails.password.trim() !== "" &&
      userDetails.PoliceRole.trim() !== "" &&
      userDetails.StationCity.trim() !== ""
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate=()=>
    {
        let flag=false
        if(userDetails.username!="" &&userDetails.userId!=""&&userDetails.email!=""&&userDetails.phone!=""&& userDetails.PoliceRole!="" && userDetails.StationCity!="")
        {
          flag=true  
        }
        return flag
    }

    const [stationselect, setstationselect] = useState([])
    console.log("stationselect",stationselect)

    const getallStationBasedOnRole= async (value) => {
      console.log("value",value)
      try {
        const result = await fetchData(`/api/getStationByRole?label=${value.value}`);
       
         console.log("result",result.data)
         if(result.data.length>0)
        {
          setstationselect(result.data)
        }
      } catch (error) {
        console.error("Error fetching incident ID:", error);
      }
    };

    useEffect(() => {
      if(userDetails.PoliceRole){
        getallStationBasedOnRole(userDetails.PoliceRole)
      }
    }, [userDetails.PoliceRole])
    
 

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      
    >
      <DialogTitle>User Detail form</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Username*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="username"
                value={userDetails.username}
                onChange={handleUserDataChange}
                error={userDetails.usernameStatus === "error"}
                success={userDetails.usernameStatus === "success"}
                placeholder="Enter your username"
              />
              {userDetails.usernameStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Username must be alphanumeric</FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                User ID*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="userId"
                value={userDetails.userId}
                onChange={handleUserDataChange}
                error={userDetails.userIdStatus === "error"}
                success={userDetails.userIdStatus === "success"}
                placeholder="Enter your user ID"
              />
              {userDetails.userIdStatus === "error" && (
                <FormControl error>
                  <FormHelperText>
                    User ID should be alphanumeric
                  </FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          <Grid style={{ zIndex: 999999, position: "relative" }} size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Police Role*
              </MaterialTypography>
            </MaterialBox>
            <MaterialSelect
              name="PoliceRole"
              value={userDetails.PoliceRole}
              onChange={handleUserDataChange}
              options={tamilNaduPoliceHierarchy?.map((value) => {
                return {
                  value: value.value,
                  label: value.label,
                };
              })}
              error={userDetails.PoliceRoleStatus === "error" ? true : false}
              success={
                userDetails.PoliceRoleStatus === "success" ? true : false
              }
              isClearable
            />
            {userDetails.PoliceRole === "error" && (
              <FormControl error>
                <FormHelperText>
                  Police Role should be in alphabets
                </FormHelperText>
              </FormControl>
            )}
          </Grid>

          {userDetails.PoliceRole && (
            <Grid size={{ xs: 12, sm: 6 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {userDetails.PoliceRole.value === "DC"
                    ? "Station City*"
                    : userDetails.PoliceRole.value === "Commissioner"
                    ? " Station District*"
                    : userDetails.PoliceRole.value === "AC"
                    ? "Station Range*"
                    : " Station Name*"}
                </MaterialTypography>
              </MaterialBox>
              <MaterialSelect
              name="StationCity"
              value={userDetails.StationCity}
              onChange={handleUserDataChange}
              // options={tamilNaduPoliceHierarchy?.map((value) => {
              //   return {
              //     value: value.value,
              //     label: value.label,
              //   };
              // })}
              options={stationselect}
              error={userDetails.StationCityStatus === "error" ? true : false}
              success={
                userDetails.StationCityStatus === "success" ? true : false
              }
              isClearable
            />
            {userDetails.StationCity === "error" && (
              <FormControl error>
                <FormHelperText>
                  Police Role should be in alphabets
                </FormHelperText>
              </FormControl>
            )}
            </Grid>
          )}

          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Email*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="email"
                value={userDetails.email}
                onChange={handleUserDataChange}
                error={userDetails.emailStatus === "error"}
                success={userDetails.emailStatus === "success"}
                placeholder="Enter your email"
              />
              {userDetails.emailStatus === "error" && (
                <FormControl error>
                  <FormHelperText>Enter a valid email address</FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, sm: 6 }}>
            <MaterialBox lineHeight={0} display="inline-block">
              <MaterialTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                Phone*
              </MaterialTypography>
            </MaterialBox>
            <Stack sx={{ width: "100%" }}>
              <MaterialInput
                name="phone"
                value={userDetails.phone}
                onChange={handleUserDataChange}
                error={userDetails.phoneStatus === "error"}
                success={userDetails.phoneStatus === "success"}
                placeholder="Enter your phone number"
              />
              {userDetails.phoneStatus === "error" && (
                <FormControl error>
                  <FormHelperText>
                    Phone number must be 10 digits
                  </FormHelperText>
                </FormControl>
              )}
            </Stack>
          </Grid>

          {oneUserData ? null : (
            <Grid size={{ xs: 12, sm: 6 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Password*
                </MaterialTypography>
              </MaterialBox>
              <Stack sx={{ width: "100%" }}>
                <MaterialInput
                  name="password"
                  type="password"
                  value={userDetails.password}
                  onChange={handleUserDataChange}
                  error={userDetails.passwordStatus === "error"}
                  success={userDetails.passwordStatus === "success"}
                  placeholder="Enter your password"
                />
                {userDetails.passwordStatus === "error" && (
                  <FormControl error>
                    <FormHelperText>
                      Password must be at least 6 characters
                    </FormHelperText>
                  </FormControl>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
        
      </DialogContent>
      <DialogActions>
      <p style={{color:"red", fontSize:"12px"}} >{error}</p>
        <Grid xs={12}>
          <MaterialButton color="dark" onClick={handleClose}>
            Cancel
          </MaterialButton>
        </Grid>
        <Grid xs={12}>
          <MaterialButton
            variant="gradient"
            color="dark"
            onClick={handleFormSubmit}
            // disabled={!isFormValid()}
          >
            Submit
          </MaterialButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UserDataForm;
